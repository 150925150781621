import { BrowserModule } from '@angular/platform-browser';
import { PlatformModule } from '@angular/cdk/platform';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TermOfUseComponent } from './term-of-use/term-of-use.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { ContactOfficeComponent } from './contact-office/contact-office.component';
import { TextMaskModule } from 'angular2-text-mask';
import { AppointmentsComponent } from './appointments/appointments.component';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatDialogModule } from '@angular/material/dialog';
import { FacilityLocationsComponent } from './facility-locations/facility-locations.component';
import { ProviderListComponent } from './provider-list/provider-list.component';
import { MyPaymentsComponent } from './my-payments/my-payments.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { enableProdMode } from '@angular/core';
import { Router } from '@angular/router';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { NgxStripeModule } from 'ngx-stripe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { StripeErrorComponent } from './stripe-error/stripe-error.component';
import { MyTasksComponent } from './my-tasks/my-tasks.component';
import { TaskDetailsComponent } from './task-details/task-details.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { SignaturePadModule } from 'angular2-signaturepad';
import { MFAComponent } from './mfa/mfa.component';
import { Page404Component } from './page404/page404.component';
import { ConversationListComponent } from './conversation-list/conversation-list.component';
import { LinkUserComponent } from './link-user/link-user.component';
import { VsaPreviewFormsModule } from 'vsa-preview-forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import * as SentryBrowser from '@sentry/browser';
import { SiteNavigatorComponent } from './site-navigator/site-navigator.component';
import { VerificationComponent } from './verification/verification.component';
import { EzAccessComponetComponent } from './ez-access-componet/ez-access-componet.component';
import { QuickLoginComponent } from './quick-login/quick-login.component';

// if (environment.production)
// Sentry.init({
//   dsn: environment.DSN,
//   environment: environment.production ? 'production' : 'dev',
//   integrations: [
//     new Integrations.BrowserTracing({
//       tracingOrigins: ['localhost', environment.URL],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//   tracesSampleRate: 1.0,
// });
SentryBrowser.init({
  dsn: environment.DSN,
  environment: environment.production ? 'production' : 'dev',
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new SentryBrowser.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
});

SentryBrowser.configureScope((scope) => {
  scope.setUser({
    'patient-id': localStorage.getItem('patient_id'),
    'user-id': localStorage.getItem('user_id'),
    'client-id': localStorage.getItem('client_id'),
    'multi-user-id': localStorage.getItem('multi_user_ids'),
  });
});

@NgModule({
  declarations: [
    AppComponent,
    TermOfUseComponent,
    HomeComponent,
    SignUpComponent,
    LoginComponent,
    DashboardComponent,
    OrderListComponent,
    OrderDetailComponent,
    ContactOfficeComponent,
    AppointmentsComponent,
    FacilityLocationsComponent,
    ProviderListComponent,
    MyPaymentsComponent,
    StripeErrorComponent,
    MyTasksComponent,
    TaskDetailsComponent,
    MFAComponent,
    Page404Component,
    ConversationListComponent,
    LinkUserComponent,
    SiteNavigatorComponent,
    VerificationComponent,
    EzAccessComponetComponent,
    QuickLoginComponent,
  ],
  imports: [
    BrowserModule,
    PlatformModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CarouselModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    ToastrModule.forRoot(),
    TextMaskModule,
    GoogleMapsModule,
    GooglePlaceModule,
    MatDialogModule,
    NgxSpinnerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    NgxStripeModule.forRoot(environment.STRIPE_KEY),
    SignaturePadModule,
    ImageCropperModule,
    VsaPreviewFormsModule,
  ],
  exports: [
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatNativeDateModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: HTTP_INTERCEPTORS,
      // useFactory: () => () => { },
      deps: [Sentry.TraceService],
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  // providers: [{
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: HttpInterceptorService,
  //   multi: true
  // }],
  bootstrap: [AppComponent],
})
export class AppModule {}
