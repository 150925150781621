import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { OrderService } from '../services/order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { StripeService } from 'ngx-stripe';
import { ToastrService } from 'ngx-toastr';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { LoginService } from '../services/login.service';
import { ProviderService } from '../services/provider.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ez-access-componet',
  templateUrl: './ez-access-componet.component.html',
  styleUrls: ['./ez-access-componet.component.css'],
})
export class EzAccessComponetComponent implements OnInit {
  recordIndex: any;
  arrayOfUsers: any;
  dynamicSlides: any;
  totalSlides: number;
  arrayOfStatments: any;
  totalStatements: any;
  paymentInfo: any;
  latestPaymentDate: string;
  errorText: string;
  blob: any;
  user_id: string;
  provider: any;
  payObj: any;
  response: any;
  patient_id: any;
  client_id: any;
  private unsubscribeSource: Subject<any>;
  constructor(
    private location: Location,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private stripeService: StripeService,
    private toastr: ToastrService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private loginService: LoginService,
    private providerService: ProviderService
  ) {
    this.route.params.subscribe((params) => {
      this.user_id = params['id'];
    });
    this.paymentInfo = {};
    this.dynamicSlides = {};
    this.arrayOfUsers = [];
    this.totalSlides = 1;
    this.totalStatements = {};
    this.arrayOfStatments = [];
  }

  ngOnInit(): void {
    this.unsubscribeSource = new Subject<any>();
    if (!this.user_id) {
      this.user_id = localStorage.getItem('user_id');
    }
    this.spinner.show();
    this.loginService.easyOptions(this.user_id).subscribe(
      (response) => {
        if (response.message === 'Success') {
          if (response.data.patient_name) {
            localStorage.setItem('patient_name', response.data.patient_name);
          }
          if (response.data.first_name) {
            localStorage.setItem('first_name', response.data.first_name);
          }
          if (response.data.last_name) {
            localStorage.setItem('last_name', response.data.last_name);
          }
          if (response.data.date_of_birth) {
            localStorage.setItem('date_of_birth', response.data.date_of_birth);
          }
          if (response.data.date_of_birth) {
            localStorage.setItem('patient_id', response.data.patient_id);
            this.patient_id = response.data.patient_id;
          }
          if (response.data.date_of_birth) {
            localStorage.setItem('client_id', response.data.client_id);
            this.client_id = response.data.client_id;
          }
          if (response.data.user_ids) {
            localStorage.setItem(
              'multi_user_ids',
              JSON.stringify(response.data.user_ids)
            );
          }
          if (response.data.med_rec_nbr) {
            localStorage.setItem('med_rec_nbr', response.data.med_rec_nbr);
          }

          if (response.data.practice) {
            this.provider = response.data.practice;
          }
          this.spinner.show();
          this.response = response;
          this.getData();
          this.getPayment(0, true);
          this.spinner.hide();
        }
      },
      (error) => {
        this.toastr.error(error.error.message);
        this.spinner.hide();
      }
    );
  }

  async getData() {
    this.recordIndex = JSON.parse(localStorage.getItem('multi_user_ids'));
    if (this.recordIndex !== null && this.recordIndex.length > 0) {
      for (var i = 0; i < this.recordIndex.length; i++) {
        this.arrayOfUsers[i] = '';
        this.arrayOfStatments[i] = '';
      }
    }
  }

  delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  getPayment(pageNum: number, showSpinner: boolean = false) {
    this.latestPaymentDate = null;
    this.getPaymentStatment(pageNum);
    if (!showSpinner) this.spinner.show();

    this.paymentInfo = this.response.data;
    this.spinner.hide();
    this.dynamicSlides = {
      paymentInfo: this.paymentInfo.client,
      orders: this.paymentInfo.orders,
    };

    if (this.arrayOfUsers[pageNum] === '') {
      this.arrayOfUsers[pageNum] = this.dynamicSlides;
    }
    this.spinner.hide();

    if (!showSpinner) this.spinner.hide();
  }

  markPaymentAsComplete(orderId, benefits_id) {
    this.spinner.show();
    this.orderService.markPaymentAsComplete(orderId, benefits_id).subscribe(
      (response) => {
        this.spinner.hide();
        if (response.code === 200) {
          this.toastr.success('Your payment has been completed successfully');
          history.pushState({}, '', '/my-payments');
          this.getPayment(0, true);
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error.error.message);
        console.log(error);
      }
    );
  }

  getPaymentStatment(pageNum: number) {
    this.totalStatements = {
      paymentStatment: this.response?.data?.paymentStatements,
      length: this.response?.data?.paymentStatements.length,
    };
    if (this.arrayOfStatments[pageNum] === '') {
      this.arrayOfStatments[pageNum] = this.totalStatements;

      this.arrayOfStatments[pageNum].paymentStatment.forEach((stat) => {
        if (
          !this.latestPaymentDate ||
          moment(stat.print_date).isAfter(this.latestPaymentDate)
        )
          this.latestPaymentDate = stat.print_date;
      });
    }
    this.spinner.hide();
  }

  checkUser() {
    Swal.fire({
      title: 'Need Authentication',
      text: "Access More Features: Sign Up or Log In to Authenticate",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      customClass: {
        popup: 'swal-resize'  // customClass should be an object
      },
      confirmButtonText: 'Proceed',
    })
    .then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/home']);
      }
    });
  }

  customOptions: OwlOptions = {
    loop: false,
    dots: true,
    navSpeed: 600,
    nav: false,
    autoHeight: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      760: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  onDragged($event: any) {
    this.spinner.show();
    if (this.arrayOfUsers[$event.startPosition] === '') {
      this.getPayment($event.startPosition, true);
    } else {
      this.spinner.hide();
      return;
    }
  }

  checkout(amount, obj: any) {
    this.spinner.show();
    this.orderService.orderCurrentSlide = 0;
    let self = this;
    self.payObj = obj;
    // Check the server.js tab to see an example implementation
    if (document.referrer) {
      localStorage.setItem('back', document.referrer);
    }
    this.orderService
      .createCheckoutEasyPay(
        self.payObj.order_id,
        self.payObj.benefits_id,
        this.user_id
      )
      .pipe(
        switchMap((response) => {
          return this.stripeService.redirectToCheckout({
            sessionId: response.data.sessionId,
          });
        })
      )
      .subscribe(
        (result) => {
          this.spinner.hide();
          if (result.error) {
            alert(result?.error?.message);
          }
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(error?.error?.message);
          console.log(error);
        }
      );
  }

  isPaymentBtnDisabled(date: string) {
    return !moment(date).isSame(moment(this.latestPaymentDate));
  }

  createStatementPaymentSession(statementId) {
    this.spinner.show();
    this.orderService
      .createStatementCheckoutSessionEasyPay(
        statementId,
        this.user_id,
        this.patient_id,
        this.client_id
      )
      .pipe(
        switchMap((response) => {
          return this.stripeService.redirectToCheckout({
            sessionId: response.data.sessionId,
          });
        })
      )
      .subscribe(
        (result) => {
          // If `redirectToCheckout` fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using `error.message`.
          if (result?.error) {
            alert(result?.error?.message);
          }
        },
        (error) => {
          this.toastr.error(error?.error?.message);
          console.log(error);
          this.spinner.hide();
        }
      );
  }

  back(): void {
    this.location.back();
  }

  navigateChatBox(practiceId, practiceName) {
    this.router.navigate([
      '/contact-office/' + practiceId + '/' + practiceName,
    ]);
  }
}
