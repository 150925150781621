import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { MatDatepickerInput } from '@angular/material/datepicker';
import { linkUserModel } from '../models/linkUser';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-link-user',
  templateUrl: './link-user.component.html',
  styleUrls: ['./link-user.component.css'],
})
export class LinkUserComponent implements OnInit {
  showPass: boolean = false;
  dobFlag: boolean;
  inputModel: linkUserModel;
  minDate: Date;
  maxDate: Date;
  client_name: string;
  form: FormGroup = new FormGroup({});
  @ViewChild('dateOfBirth') dateOfBirth: ElementRef;
  @ViewChild(MatDatepickerInput) datepickerInput: MatDatepickerInput<any>;
  constructor(
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    // this.isMatch=true
    this.dobFlag = false;
    this.client_name = '';
    this.minDate = new Date(1910, 4, 12);
    this.maxDate = new Date();
    this.inputModel = new linkUserModel();

    if (localStorage.getItem('client_id')) {
      this.inputModel.client_id = localStorage.getItem('client_id');
    }
    if (localStorage.getItem('patient_id')) {
      this.inputModel.patient_id = localStorage.getItem('patient_id');
    }
    if (localStorage.getItem('client_name')) {
      this.client_name = localStorage.getItem('client_name');
    }

    this.form = fb.group({
      cell_phone: ['', [Validators.required]],
      username: [
        '',
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(20),
      ],
      password: ['', Validators.required, Validators.minLength(8)],
    });
  }

  onDateChange(event: any) {
    this.dobFlag = false;
    var x;
    x = this.dateOfBirth.nativeElement.value;
    x = moment(x).format('YYYY-MM-DD');
    if (x === 'Invalid date') {
      this.dobFlag = true;
    }
  }

  onClick(event) {
    this.dateOfBirth.nativeElement.focus();
    this.dateOfBirth.nativeElement.setSelectionRange(0, 0);
  }

  public mask = {
    guide: true,
    showMask: true,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  };

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {}

  numericOnly(event): boolean {
    // restrict e,+,-,E characters in  input type number
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  togglePassword() {
    this.showPass = !this.showPass;
  }

  linkUser() {
    this.spinner.show();
    this.inputModel.date_of_birth = this.dateOfBirth.nativeElement.value;
    this.inputModel.username = this.f.username.value;
    this.inputModel.password = this.f.password.value;

    this.inputModel.date_of_birth = moment(
      this.inputModel.date_of_birth
    ).format('YYYY-MM-DD');

    if (!this.inputModel.cell_phone) {
      this.spinner.hide();
      return this.toastr.error('Please enter cell phone');
    }

    if (
      !this.inputModel.date_of_birth ||
      this.inputModel.date_of_birth === 'Invalid date'
    ) {
      this.spinner.hide();
      return this.toastr.error('Please enter date of birth');
    }

    this.linkAccount();
  }

  linkAccount() {
    this.loginService.linkAccount(this.inputModel).subscribe(
      (response) => {
        this.spinner.hide();
        if (response.data.token) {
          localStorage.setItem('token', response.data.token);
        }
        if (response.data.patient_name) {
          localStorage.setItem('patient_name', response.data.patient_name);
        }
        if (response.data.first_name) {
          localStorage.setItem('first_name', response.data.first_name);
        }
        if (response.data.last_name) {
          localStorage.setItem('last_name', response.data.last_name);
        }
        if (response.data.date_of_birth) {
          localStorage.setItem('date_of_birth', response.data.date_of_birth);
        }
        if (response.data.user_ids) {
          localStorage.setItem(
            'multi_user_ids',
            JSON.stringify(response.data.user_ids)
          );
        }
        if (response.data.med_rec_nbr) {
          localStorage.setItem('med_rec_nbr', response.data.med_rec_nbr);
        }
        window.location.href = '/dashboard';
      },
      (error) => {
        this.spinner.hide();
        if (error && error.error.code === 303) {
          if (error.error.data && error.error.data.cell_phone_end) {
            localStorage.setItem(
              'cell_phone_end',
              error.error.data.cell_phone_end
            );
          }
          window.location.href = '/mfa';
        } else if (error && error.error.code === 412) {
          this.addDefaultCredentials();
        } else {
          this.toastr.error(error.error.message);
          console.log(error);
        }
      }
    );
  }

  addDefaultCredentials() {
    Swal.fire({
      title: 'Add Credentials',
      text: 'Would you like to make these credentials default for all your accounts?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      customClass: {
        popup: 'swal-resize'  // customClass should be an object
      },
      confirmButtonText: 'Yes, Confirm it!',
    })
    .then((result) => {
      if (result.isConfirmed) {
        this.spinner.hide();

        const defaultCredentails = {
          cell_phone: this.inputModel.cell_phone,
          date_of_birth: this.inputModel.date_of_birth.replace(/-/g, ''),
          username: this.inputModel.username,
          password: this.inputModel.password,
        };

        this.loginService.addDefaultCredentials(defaultCredentails).subscribe(
          () => {
            this.spinner.show();
            this.linkAccount();
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(error);
          }
        );
      }
    });
  }
}
