<div class="screen screen-4">
    <div class="logo">
      <div *ngIf="!brandlogo" class="image-placeholder"></div>
      <img
        *ngIf="this.brandlogo"
        class="logo-img"
        src="{{ this.brandlogo }}"
        alt="logo"
      />
    </div>
    
    <div [@fadeIn]>
      <div class="login-field-button">
        <div class="input-otr-password">
          <form [formGroup]="signinForm" (ngSubmit)="login()">
            <div class="form-group">
              <label class="input-text-1">Date of birth:</label>
              <input
                type="date"
                class="input"
                formControlName="dateOfBirth"
              />
              <!-- Display validation errors -->
              <div *ngIf="f.dateOfBirth.invalid && (f.dateOfBirth.dirty || f.dateOfBirth.touched)">
                <small class="error-text" *ngIf="f.dateOfBirth.errors?.required">
                  Date of birth is required.
                </small>
                <small class="error-text" *ngIf="f.dateOfBirth.errors?.futureDate">
                  Date of birth cannot be in the future.
                </small>
              </div>
            </div>
    
            <div class="container-center">
              <button
                class="btn login-button"
                type="submit"
                [disabled]="signinForm.invalid"
              >
                LOGIN
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    

    <!-- Footer -->
    <p class="power-footer">Powered By VYTLsft</p>
</div>
