import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stripe-error',
  templateUrl: './stripe-error.component.html',
  styleUrls: ['./stripe-error.component.css']
})
export class StripeErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
