<!--==========================
           Screen-2 End Here
    ===========================-->
<div class="screen screen-2">
  <div class="logo">
    <img class="logo-img" src="assets/img/logo.png" alt="logo" />
  </div>
  <a href="/home">
    <svg
      class="icon"
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.47 17.78a.75.75 0 101.06-1.06l-3.97-3.97h8.69a.75.75 0 000-1.5H8.56l3.97-3.97a.75.75 0 00-1.06-1.06l-5.25 5.25c-.002 0-.003.003-.005.005a.747.747 0 00-.215.523v.004a.748.748 0 00.22.529m5.25 5.25l-5.25-5.25z"
        fill="#fff"
      />
    </svg>
  </a>
  <p class="heading">Terms Of Use</p>
  <div class="area">
    <div class="text-area termsx" style="font-weight: normal !important">
      <span style="font-weight: bold">1. PROPERTY OF THE WEBSITE</span>
      <p>
        The property of the website http:// VYTLSFT.com, and the VYTL SFT mobile
        application belongs to: VYTL SFT LLC. The General Terms of Use and
        Privacy Policy governing the use of the services offered through the
        VYTL SFT, LLC (“VYTL SFT”, “us”, “we”) Website (the “Service” or
        “Services”), the use of VYTL SFT mobile application (“Application”)
        through mobile devices that enables you to have access to the Internet
        and browser, the conditions of processing personal data provided by you
        through the use of our Website and Application, and the relationships
        between you (Users), Physicians & Health Care Professionals (“HCP”) and
        VYTL SFT. Subject to your acceptance of and adherence to these Terms of
        Use and Privacy Policy, VYTL SFT hereby grants you a limited,
        non-exclusive license to utilize the VYTL SFT Services as set forth
        herein. The terms “you” and “user” shall refer to all individuals and
        entities that access or utilize the VYTL SFT Services. Services are only
        available to registered Users and HCP. The User may also access to VYTL
        SFT Services through the Application. By your affirmative actions of
        using the Website or the Application, as applicable, the User and the
        HCP expressly consent to and accept the General Terms of Use and Privacy
        Policy contained herein.
      </p>
      <span style="font-weight: bold">2. DISCLAIMER OF THE PLATFORM</span>
      <p>
        VYTL SFT is a platform, which connects the Users and HCP registered on
        the website, acting as merely intermediary. The information shared by
        the users and the HCP may contain Protected Health Information and
        personal data information. However, VYTL SFT, LLC does not own any of
        such data or information shared between the Users and HCP. Thus, any
        data provided by the Users or the HCP belong exclusively to them.
        Therefore, VYTL SFT, LLC cannot be held liable for any event occurred
        relating to such data. Despite the fact that VYTL SFT, LLC does not own
        any of such data shared by the Users and HCP, the company has applied
        all the measures required for the safeguarding and HIPAA compliance of
        Personal Data, in order to grant the protection of the data and
        information of the Users and HCP.
      </p>
      <span style="font-weight: bold"> 3. REGISTRATION PROCESS</span>
      <p>
        In accordance with the provisions of the law governing these General
        Terms of Use and the Privacy Policy, access to the VYTL SFT Services is
        limited to persons over 18 years of age. If you are under 18 we will
        require you prior to use, expressly, to secure authorization in writing
        from your parents or guardians. Therefore, when registering on VYTL SFT
        Services, you expressly state and ensure that you fulfil this
        requirement and otherwise accept the consequences if they arise. If you
        are unable to comply with the conditions laid down in this paragraph,
        please do not register at VYTL SFT. If you are a HCP, we confirm in
        accordance with the provisions of the law governing these General Terms
        of Use and the Privacy Policy, that access to VYTL SFT is limited to
        medical professionals who are qualified to meet all of the requirements
        demanded by their profession in the state in which they pursue this
        profession. Therefore, by registering on our website as a HCP, you
        expressly state and guarantee that you meet this requirement and accept
        the consequences that may arise if otherwise. To access to VYTL SFT
        Services, we offer Users and HCPs a registration system that will manage
        your personal information and create customized access with a unique URL
        and PIN. If you are a User, you can access the registration system
        through the Application through web access,
      </p>
      <span style="font-weight: bold">4.MODIFICATIONS TO THE TERMS OF USE</span>
      <p>
        We may make changes to the Terms of Use from time to time. A link to the
        most current Terms of Use will be available on the home page of the VYTL
        SFT Site and we will indicate the date of the “Latest Update” at the
        bottom of the Terms of Use. You are held responsible to visit this page
        periodically to review the most current Terms of Use. Your continued use
        of the VYTL SFT Services following the posting of such changes
        constitutes your acceptance of the amended Terms of Use. After
        incorporation of the new content in the General Terms of Use and Privacy
        Policy, the User shall declare to have read and accepted the new content
        and all its content. In case of modification, change or termination of
        any of the content in these General Terms and Conditions of Use and
        Privacy Policy under sentence or judgment given by a competent court or
        under an award made by an arbitrator or by a court of arbitration, the
        other content that have not been modified, changed, or resolved remain
        in force and shall be valid for both parties.
      </p>

      <span style="font-weight: bold">5.PRECAUTIONS</span>
      <p>
        VYTL SFT provides mobile platforms so individuals can physicians orders
        and the status of said orders. However, it does not provide any legal,
        medical, or psychological advice, diagnoses, or treatment. Therefore,
        you assume full risk and responsibility for your use of information
        obtained through the VYTL SFT Services. You acknowledge that all of the
        information and content in the VYTL SFT Services is for informational
        purposes only and is not intended to replace the relationship between
        you and HCP. We are not a licensed medical care provider and have no
        expertise in diagnosing, examining, or treating medical conditions
        thereby we do not give medical, legal, or psychological advice,
        diagnoses, or treatment. The VYTL SFT Services may provide helpful
        Health-Related Information, but it is not intended to substitute for
        professional advice, diagnoses, or treatment, or your judgment.
        “Protected Health Information” for the purposes of this Terms of Use
        means all individually identifiable health information, including
        information related to an individual’s physical or mental health. Never
        disregard professional medical advice or delay in seeking it because of
        something you have read on or through the VYTL SFT Services. You assume
        full risk and responsibility for the use of or reliance on information
        you obtain from or through the VYTL SFT Services. You are not a patient
        or client of VYTL SFT. You must contact a professional for medical,
        legal, or psychological advice. If you think you may have a medical
        emergency, dial 911 immediately.
      </p>
      <span style="font-weight: bold"
        >6.TERMINATING YOUR AGREEMENT WITH VYTL SFT SOCIAL DIABETES</span
      >
      <p>
        These General Terms of Use will continue to apply until terminated by
        either you or VYTL SFT in accordance with the following provisions: – If
        you want to dissolve your relationship with VYTL SFT, you may do so by
        notifying VYTL SFT at anytime or by canceling your VYTL SFT account.
        Your notice should be sent in writing to the email support VYTLSFT.com.
        – VYTL SFT may dissolve your legal relationship with you at any time if:
        You breach any of the clauses in the General Conditions of Use and
        Privacy Policy, VYTL SFT is required by law; VYTL SFT no longer provides
        Services to Users and HCP within the User’s state of residence or the
        state in which the HCP practices their profession or the state in which
        the User uses the Services. The result of the resolution will be to
        cancel the Services and cancel the account of the User or the HCP, as
        appropriate, and delete all of their personal data.
      </p>
      <span style="font-weight: bold"
        >7. OWNERSHIP OF THE VYTL SFT SERVICES</span
      >
      <p>
        VYTL SFT owns or has rights to all of the content in the VYTL SFT
        Services, excluding User Protected Health Information, which belongs
        exclusively to the User and/or the HCP, as stated in Clause 2. The
        contents of the VYTL SFT Services include: designs, text, graphics,
        images, video, information, logos, button icons, software, audio files,
        computer code, and other VYTL SFT content. All VYTL SFT Content and the
        compilation (meaning the collection, arrangement, and assembly) of all
        VYTL SFT Content are the property of VYTL SFT, LLC or its licensors and
        are protected under copyright. For this reason, the User shall refrain
        from reproducing, adapting, combining or integrating into other
        applications, arranging or otherwise handling or transforming, and from
        publicly communicating or publishing the VYTL SFT Content. VYTL SFT is
        committed to respecting the rights of industrial and intellectual
        property of others and expects Users to act in a similar manner.
        According to the law in effect at the time, VYTL SFT responds promptly
        to claims for infringement on industrial or intellectual property rights
        that are allegedly committed by Users on the VYTL SFT Website. Such
        claims shall, where appropriate, be notified to the appropriate
        authorities. VYTL SFT reserves the right to use anonymous contributions
        made by all Users in the testimonial area, or post on the VYTL SFT, in
        order to promote the Services offered through the Website by, but not
        limited to, the insertion of advertisements in different media or
        publishing books. VYTL SFT reserves the right to remove links to any
        website that Users place in the testimonial area, or post on the
        Website, if they have actual knowledge that the activities in such
        websites, or content thereof, are illegal or violate the rights of
        others, or if it is required to do so under a court order, arbitration
        award, or administrative order. Is completely forbidden and VYTL SFT
        reserves the right to remove (with their absolute discretion) any
        content posted by a User in the testimonial area, or in the forum on the
        Website, that infringes industrial or intellectual property of others,
        or is contrary to the law, morality or public order.
      </p>
      <span style="font-weight: bold"
        >8.DISCLAIMERS, LIMITATIONS AND PROHIBITIONS</span
      >
      <p>
        You are responsible for your actions when using and relying on the VYTL
        SFT Services. Therefore, You are solely responsible for Your User
        Protected Health Information and Personal Data stored with the mobile
        application. You assume all risks associated with Your Protected Health
        Information including anyone’s reliance on its quality, accuracy, or
        reliability. You may expose yourself to liability if, for example, Your
        Protected Health Information contains material that is false,
        intentionally misleading, or defamatory; violates third-party rights; or
        contains material that is unlawful or advocates the violation of any law
        or regulation. You are the solely responsible for the transmission of
        Protected Health Information and personal data through VYTL SFT’s
        platforms. Therefore, you undertake to comply with all the security
        measures and obligations applicable, excluding VYTL SFT of any liability
        regarding such data. Both User and the HCP use the VYTL SFT Services in
        any of its versions under their sole, exclusive and unique
        responsibility. We may change or discontinue, temporarily or
        permanently, any feature or component of the VYTL SFT Services at any
        time without further notice. You agree that we shall not be liable to
        you or to any third party for any modification, suspension or
        discontinuance of any feature or component of the VYTL SFT Services. We
        are not responsible for any disputes or disagreements between you and
        any third party, including clinicians, with whom you interact using the
        VYTL SFT Services. You assume all risk associated with dealing with
        third parties. You agree to resolve disputes directly with the other
        party. You release VYTL SFT of all claims, demands, and damages in
        disputes among users of the VYTL SFT Services. You also agree not to
        involve us in such disputes. Use caution and common sense when using the
        VYTL SFT Services. The VYTL SFT Services may provide links or references
        to websites operated by third parties. You are responsible for
        evaluating whether you want to access or use them. We are not
        responsible for and do not endorse any features, content, advertising,
        products, or other materials on other websites or applications. We
        suggest that you read the terms of use and privacy policies (if any) on
        those third-party websites. You assume all risk and we disclaim all
        liability arising from your use of them. The User and the HCP accepted
        there is no claim under any circumstances or any compensation for any
        damage or injury caused by the use of the VYTL SFT Services in any of
        their versions.
      </p>
      <span style="font-weight: bold">9.PRIVACY POLICY</span>
      <p>
        GENERAL INFORMATION AND CONSENT This privacy policy applies to the
        Website and the Application. Existence of file: In compliance with the
        Organic Law 15/1999 on Protection of Personal Data, we hereby inform the
        Users and HCP that their personal data and other information provided
        through the registration form and during the use of the VYTL SFT
        Services and during the download, installation or use of the Application
        shall be included and kept in a database for processing, whose
        responsibility is VYTL SFT, LLC, (regardless of the fact that they are
        not transferred to VYTL SFT in any case). Purpose and Consent: By
        voluntarily providing personal data, and completing and submitting the
        registration form for the User and HCP, or to use the Services or the
        Application, and by clicking on the corresponding checkbox, the User and
        the HCP give their express consent for VYTL SFT to process their
        personal data in order to manage the use of the VYTL SFT Services, or
        the Application’s functionality, and to receive information or news
        related to the Website or VYTL SFT Application by any means, including
        electronic Emails and SMS (Text Messages). Statistics Report: Please
        note that VYTL SFT may use the data provided by the Users and HCP
        through the VYTL SFT Services in order to draw general conclusions based
        on these data and statistical reporting to improve our Services. In
        preparing these reports, the data will be in an aggregate form so it
        will always be used anonymously and without any elements that identify
        the User or HCP who provide such data. Therefore no identification data
        such as email account, first name and last name of any User or HCP will
        be included in these statistical reports. Questionnaires, Surveys and
        Essays: Please note that VYTL SFT may send Users and HCP electronic
        communications to their email or via SMS (text messages), inviting them
        to participate, if you chose to do so voluntarily, in survey
        questionnaires and electronic communications as well as in clinical
        trials or of a nonclinical nature (“Questionnaires, Surveys and
        Studies”) organized by VYTL SFT or third parties other than VYTL SFT,
        such as associations or organizations of any type or in conjunction with
        VYTL SFT with one or more third parties, in order to mine data and
        findings that advance research. Participation in these Questionnaires,
        Surveys and Tests will be entirely voluntary and optional. In case you
        chose to participate in Questionnaires, Surveys and Tests, your
        participation is subject to the General Terms and Privacy Policy
        governing such Questionnaires, Surveys and Tests and you acknowledge, in
        that case, if you voluntarily accept or reject these General Terms and
        Privacy Policy prior to your participation in these surveys or
        questionnaires or electronically-accessed trials. By clicking on the
        corresponding checkbox you state and expressly agree to receive
        communications inviting you to participate in online surveys and
        questionnaires, as well as trials of a clinical or non-clinical nature.
        However, you are free to participate or not participate, and you do not
        have to nor will you be under any obligation to participate in those
        proposals or invitations contained within such communications. VYTL SFT
        acts as intermediary between the Users and HCP, and those associations
        and organizations who want to do some kind of research or analysis
        activity to improve the lives of people.
      </p>
      <span style="font-weight: bold"
        >10.WHAT PERSONAL INFORMATION WE COLLECT?</span
      >
      <p>
        “Personal Information” for the purposes of this policy means information
        that identifies you, such as your name, address, phone number or email
        address. We do not consider Personal Information to include information
        that has been anonymized so that it does not allow a third party to
        easily identify a specific individual. We collect Personal Information
        when you: communicate with us through customer support communicate with
        us by sending a feature request
      </p>
      <span style="font-weight: bold"
        >11.INFORMATION YOU ELECT TO SHARE WITH OTHERS</span
      >
      <p>
        Through the VYTL SFT Services, you can access and store Protected Health
        Information. Many users find this to be an important motivator in
        achieving their recovery goals. “Protected Health Information” for the
        purpose of this Policy means individually identifiable health
        information, including information related to an individual’s physical
        or mental health. Users expressly agree that the HCP will have access to
        the data provided by the VYTL SFT User in order that their HCP can
        follow up and monitor the User, and can give them the advice they
        consider appropriate for as long as the User decides. Consequently, the
        User expressly authorizes VYTL SFT to transfer the User’s personal data
        to communicate with their VYTL SFT HCP. In any case, the User’s personal
        data will not be disclosed or transferred by any party other than their
        HCP on the Website under the terms listed above without the User’s
        express prior express. At any time, the User and the HCP may terminate
        their relationship with VYTL SFT by using one of the options that is
        available to both in their configuration panels. From that moment that
        the HCP or the User decide to end their relationship through the
        Website, the person that the User designated as their HCP will
        immediately cease to access the data provided by the User to VYTL SFT,
        and the User will no longer have immediate access to the data stored
        with their HCP in VYTL SFT. Both the User and the HCP may disengage
        other Users and HCP, respectively, at any time.
      </p>
      <span style="font-weight: bold">12.UPDATES TO THIS POLICY</span>
      <p>
        We may update this Policy periodically. The date last revised appears at
        the bottom of the Policy. Changes take effect immediately upon posting.
      </p>
      <span style="font-weight: bold">13. DATA SECURITY</span>
      <p>
        Despite the fact that VYTL SFT, LLC does not process or store the data
        shared by the Users and HCP, the company has applied all the measures
        required for the “high-security-level-files” by the Organic Law 15/1999
        on Protection of Personal Data, in order to grant the protection of the
        data and information of the Users and HCP. VYTL SFT ensures absolute
        confidentiality and privacy of our Users and HCP personal data, and has
        therefore adopted essential security measures to prevent alteration,
        loss, treatment or unauthorized access to guarantee its integrity and
        safety, and has especially taken the measures provided for this in the
        Royal Decree 1720/2007 of 21 December, approving the Regulations
        implementing the Organic Law 15/1999 of 13 December, on the protection
        of personal data. However, VYTL SFT is in no way liable for any
        incidents that may arise with respect to personal data that derive
        either from an attack or unauthorized access to systems that is
        impossible to detect by the security measures implemented. All of your
        Health-Related Information recorded by the means of the VYTL SFT
        Services is stored within your mobile device and is in no way accessible
        by us. We exercise no control over how your information is stored,
        maintained, or displayed by third parties that you have shared your
        Health-Related Information with.
      </p>

      <span style="font-weight: bold">14. COOKIES</span>
      <p>
        Our Website uses automatic data collection systems, not directly
        provided by the User and the HCP, such as cookies. Cookies are files
        that are installed on the hard drive of the User’s and the HCP
        computers, or in the memory on the default browser pre-configured in the
        computer’s operating system, to identify said User or HCP. While it does
        not contain intelligible information, it associates the User and HCP’s
        identity with personal data that they leave on the Website. Cookies are
        located on a VYTL SFT server so that only VYTL SFT can process and/or
        manage the information collected and obtained through cookies – and
        exclusively only anonymously, and aggregated in order to optimize the
        Services of the Website regarding the specific requirements and
        preferences of Users and HCPs expressed through their browsers. On
        entering the VYTL SFT Website, the User and the HCP are giving their
        consent to install the aforementioned cookies on their hard disk. If the
        User or HCP does not want to install a cookie on their hard drive, they
        must configure their Internet browser software in order to disable them.
      </p>
      <span style="font-weight: bold">15.GOVERNING LAW AND JURISDICTION</span>
      <p>
        The General Conditions of Use and Privacy Policy shall be governed by
        Utah State law prevalent at the time. Any dispute arising from use of
        the Website or the Application by the User or by the HCP, as well as any
        issues that may arise concerning the interpretation, application and
        enforcement of these General Terms of Use or the Privacy Policy shall be
        submitted to the exclusive jurisdiction of the State of Utah.
      </p>
    </div>
  </div>

  <!-- <div class="area">
            <textarea name="textarea" id="textarea" class="text-area" placeholder="Scrolling Text Box">
            </textarea>
        </div> -->
  <!-- <p class="text-email">Enter email to recieve a copy of Terms</p> -->
  <form class="accept-form" (ngSubmit)="onSubmit()">
    <!-- <div class="email-otr">
            <p class="email">Email Address (Optional)</p>
            <input class="input" type="text" name="email_address" id="email_address"
                [(ngModel)]="termInputModel.email_address" required [textMask]="{mask: this.mask}"
                pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$">
        </div> -->
    <div class="action-otr">
      <button class="btn-term hand-cursor">ACCEPT TERMS OF USE</button>
      <!-- <a href="#" class="btn-term">ACCEPT TERMS OF USE</a> -->
    </div>
  </form>
</div>

<!--==========================
           Screen-2 End Here
    ===========================-->
