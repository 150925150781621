import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProviderService } from '../services/provider.service';

@Component({
  selector: 'app-provider-list',
  templateUrl: './provider-list.component.html',
  styleUrls: ['./provider-list.component.css'],
})
export class ProviderListComponent implements OnInit {
  providerList: any;
  constructor(
    private providerService: ProviderService,
    private toastr: ToastrService
  ) {
    this.getAllClients();
  }

  ngOnInit(): void {}

  getAllClients() {
    this.providerService.getAllClients().subscribe(
      (response) => {
        this.providerList = response.data;
      },
      (error) => {
        this.toastr.error(error.error.message);
        console.log(error);
      }
    );
  }

  navigateToConversationList(practice): void {
    window.location.href = '/conversation-list?practice_id=' + practice;
  }
}
