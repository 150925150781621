import { Component, HostBinding, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../services/login.service';
import { OrderService } from '../services/order.service';

@Component({
  selector: 'app-quick-login',
  templateUrl: './quick-login.component.html',
  styleUrls: ['./quick-login.component.css'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 }))),
    ]),
    trigger('fadeIn', [
      transition(':enter', [style({ opacity: 0 }), animate(760)]),
    ]),
  ],
})
export class QuickLoginComponent implements OnInit {
  @HostBinding('style.--primary-color') Primary_color = localStorage.getItem(
    'primary_color'
  )
    ? localStorage.getItem('primary_color')
    : '#073786';
  @HostBinding('style.--secondary-color') Secondry_color = localStorage.getItem(
    'secondry_color'
  )
    ? localStorage.getItem('secondry_color')
    : '#0D66FA';
  @HostBinding('style.--primary_color_light') Primary_color_light =
    localStorage.getItem('primary_color_light');
  primary_color_light: string;

  brandlogo: string;
  signinForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private orderService: OrderService,
    private fb: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  async ngOnInit(): Promise<void> {
    this.signinForm = this.fb.group({
      dateOfBirth: [
        '',
        [
          Validators.required, // Required validation
          this.dateValidator, // Custom date validator
        ],
      ],
    });
    await this.checkBrandLogo();
  }

  dateValidator(control: any) {
    const today = new Date();
    const inputDate = new Date(control.value);
    if (!control.value) {
      return { required: true };
    }
    if (inputDate > today) {
      return { futureDate: true };
    }
    return null;
  }

  delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
  checkBrandLogo = async () => {
    this.spinner.show();
    await this.delay(2000);
    if (localStorage.getItem('brand_logo')) {
      this.brandlogo = localStorage.getItem('brand_logo');
    } else {
      this.brandlogo = '../../assets/img/logo.png';
    }
    this.spinner.hide();
  };

  login() {
    this.spinner.show();
    const user_id = localStorage.getItem('user_id');
    if (this.signinForm.invalid) {
      this.toastr.error('Please fix the errors in the form.');
      return;
    }

    let body = {
      date_of_birth: this.signinForm.value.dateOfBirth,
      user_id: user_id,
    };
    if (!!user_id) {
      this.loginService.quickLogin(body).subscribe(
        (response) => {
          if (response.data.user_ids.length) {
            localStorage.setItem(
              'client_id',
              response.data.user_ids[0].client_id
            );
            localStorage.setItem(
              'patient_id',
              response.data.user_ids[0].patient_id
            );
          }

          if (response.data.token) {
            localStorage.setItem('token', response.data.token);
          }
          if (response.data.patient_name) {
            localStorage.setItem('patient_name', response.data.patient_name);
          }
          if (response.data.first_name) {
            localStorage.setItem('first_name', response.data.first_name);
          }
          if (response.data.last_name) {
            localStorage.setItem('last_name', response.data.last_name);
          }
          if (response.data.date_of_birth) {
            localStorage.setItem('date_of_birth', response.data.date_of_birth);
          }
          if (response.data.med_rec_nbr) {
            localStorage.setItem('med_rec_nbr', response.data.med_rec_nbr);
          }
          if (response.data.user_ids) {
            localStorage.setItem(
              'multi_user_ids',
              JSON.stringify(response.data.user_ids)
            );
          }
          localStorage.setItem(
            'credentials_registered',
            response.data.credentials_registered
          );
          this.spinner.hide();
          this.router.navigate(['dashboard']);
        },
        (error) => {
          this.spinner.hide();
          this.toastr.toastrConfig.timeOut = 3000;
          if (error.error) {
            if (error.error.code === 303) {
              if (error.error.data && error.error.data.cell_phone_end) {
                localStorage.setItem(
                  'client_id',
                  error.error.data.client_id
                );
                localStorage.setItem(
                  'patient_id',
                  error.error.data.patient_id
                );
                localStorage.setItem(
                  'cell_phone_end',
                  error.error.data.cell_phone_end
                );
              }
              this.router.navigate(['mfa']);
            } else {
              if (error.error.code === 400) {
                this.toastr.error(error.error.message);
                return;
              }
              this.toastr.error(error.error.message);
            }
          } else {
            // this.inputModel.pin_code = '';
            this.toastr.error(error);
          }
        }
      );
    } else {
      this.toastr.error(
        'Please use the link from the SMS to access the application.'
      );
      this.spinner.hide();
    }
  }

  getLightColor(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
          result[3],
          16
        )},0.6)`
      : null;
  }

  get f(): any {
    return this.signinForm.controls;
  }
}
