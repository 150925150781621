import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Talk from 'talkjs';

@Injectable({
  providedIn: 'root'
})
export class TalkService {

  constructor() {
    // this.loadedPopups = [];

  }

  async createUser(applicationUser: any) {
    await Talk.ready;
    return new Talk.User({
      id: applicationUser.id,
      name: applicationUser.username,
      photoUrl: applicationUser.photoUrl,
      role: applicationUser.role,
      custom: applicationUser.custom

    });
  }

  private static APP_ID = environment.TALKJS_APP_ID;
  private currentTalkUser: Talk.User;

  async createCurrentSession(practiceId) {
    await Talk.ready;
    const user = {
      id: localStorage.getItem('patient_id').toLowerCase(),
      username: localStorage.getItem('patient_name'),
      custom: {
        'practiceId': practiceId,
        'clientId': localStorage.getItem('client_id'),
        'first_name': localStorage.getItem('first_name'),
        'last_name': localStorage.getItem('last_name'),
        'date_of_birth': localStorage.getItem('date_of_birth'),
        'med_rec_nbr': localStorage.getItem('med_rec_nbr'),
      },
      role : 'patient'
    };
    this.currentTalkUser = await this.createUser(user);
    const session = new Talk.Session({
      appId: TalkService.APP_ID,
      me: this.currentTalkUser
    });
    return session;
  }

  async createInbox(session: Talk.Session, otherUserId, OtherUserName) {
    const otherApplicationUser = {
      id: otherUserId,
      username: OtherUserName,
      role : "assistant",
      custom: {
        'practiceId': otherUserId,
        'clientId': localStorage.getItem('client_id'),
      }
    };

    const conversation = await this.getOrCreateConversation(session, otherApplicationUser);
    return session.createInbox({ showFeedHeader: false, selected: conversation, showMobileBackButton: false });
  }

  private async getOrCreateConversation(session: Talk.Session, otherApplicationUser: any) {
    const otherUser = await this.createUser(otherApplicationUser);
    const conversation = session.getOrCreateConversation(Talk.oneOnOneId(this.currentTalkUser, otherUser));
    conversation.setParticipant(this.currentTalkUser);
    conversation.setParticipant(otherUser);
    return conversation;
  }
}
