import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private httpClient: HttpClient) {}

  private dob = '';
  private lastName = '';
  private cellPhone = '';

  setDataForSignup(lastName: string, dob: string) {
    this.lastName = lastName;
    this.dob = dob;
  }

  getDataForSignup() {
    return {
      lastName: this.lastName,
      dob: this.dob,
    };
  }

  setDataForlinkUser(cellPhone: string, dob: string) {
    this.cellPhone = cellPhone;
    this.dob = dob;
  }

  getDataForlinkUser() {
    return {
      cellPhone: this.cellPhone,
      dob: this.dob,
    };
  }

  doesUserExist(client_id: string, patient_id: string): Observable<any> {
    return this.httpClient
      .get<any>(
        environment.URL +
          `account/patient/${patient_id}/client/${client_id}/exists`
      )
      .pipe(map((response) => response || {}));
  }

  signUp(inputModel: any): Observable<any> {
    return this.httpClient
      .post<any>(environment.URL + 'account', inputModel)
      .pipe(map((response) => response || {}));
  }

  easyOptions(inputModel: any): Observable<any> {
    const headers = new HttpHeaders({
      user_id: inputModel,
    });
    return this.httpClient
      .get<any>(environment.URL + 'payment/easy-access', {
        headers: headers,
      })
      .pipe(map((response) => response || {}));
  }

  verification(inputModel: any): Observable<any> {
    return this.httpClient
      .post<any>(environment.URL + 'account/verify-patient', inputModel)
      .pipe(map((response) => response || {}));
  }

  linkAccount(inputModel: any): Observable<any> {
    return this.httpClient
      .post<any>(environment.URL + 'account/link', inputModel)
      .pipe(map((response) => response || {}));
  }

  addDefaultCredentials(inputModel: any): Observable<any> {
    return this.httpClient
      .put<any>(environment.URL + 'account/add-default-credentials', inputModel)
      .pipe(map((response) => response || {}));
  }

  login(inputModel: any): Observable<any> {
    return this.httpClient
      .post<any>(environment.URL + 'account/login', inputModel)
      .pipe(map((response) => response || {}));
  }

  quickLogin(inputModel: any): Observable<any> {
    return this.httpClient
      .post<any>(environment.URL + 'account/login-quick', inputModel)
      .pipe(map((response) => response || {}));
  }

  sendCode(inputModel: any): Observable<any> {
    return this.httpClient
      .post<any>(environment.URL + 'account/create-mfa-pin', inputModel)
      .pipe(map((response) => response || {}));
  }

  resendCode(inputModel: any): Observable<any> {
    return this.httpClient
      .post<any>(environment.URL + 'account/resend-code', inputModel)
      .pipe(map((response) => response || {}));
  }

  resendQuickCode(inputModel: any): Observable<any> {
    return this.httpClient
      .post<any>(environment.URL + 'account/quick-login/resend-code', inputModel)
      .pipe(map((response) => response || {}));
  }

  verifyCode(inputModel: any): Observable<any> {
    return this.httpClient
      .post<any>(environment.URL + 'account/verify-code', inputModel)
      .pipe(map((response) => response || {}));
  }

  verifyQuickCode(inputModel: any): Observable<any> {
    return this.httpClient
      .post<any>(environment.URL + 'account/quick-login/verify-code', inputModel)
      .pipe(map((response) => response || {}));
  }

  sendMFAForPassword(data: { username: any }): Observable<any> {
    return this.httpClient
      .post<any>(environment.URL + 'account/reset-password', data)
      .pipe(map((response) => response || {}));
  }

  sendMFAForPasswordByDetails(data: {
    last_name: any;
    date_of_birth: any;
  }): Observable<any> {
    return this.httpClient
      .post<any>(environment.URL + 'account/reset-account', data)
      .pipe(map((response) => response || {}));
  }

  resetCredentials(inputModel: any): Observable<any> {
    return this.httpClient
      .put<any>(environment.URL + 'account/reset-credentials', inputModel)
      .pipe(map((response) => response || {}));
  }

  resetPIN(clientId: string, patientId: string): Observable<any> {
    const url = `${environment.URL}account/client/${clientId}/patient/${patientId}/reset/pin`;
    return this.httpClient.delete<any>(url).pipe(
      map((response) => response || {}),
      catchError(this.handleError<any>('resetPIN'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
