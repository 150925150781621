import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../services/dashboard.service';
import { Router } from '@angular/router';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 }))),
    ]),
    trigger('fadeIn', [
      transition(':enter', [style({ opacity: 0 }), animate(760)]),
    ]),
  ],
})
export class DashboardComponent {
  response: any;
  showSwitchButton: boolean = false;
  registerCredentials: boolean = false;
  form: FormGroup = new FormGroup({});
  username: string;
  password: string;
  confirm_password: string;
  showPass: boolean = false;
  showConfirmPass: boolean = false;
  client_id: string;
  patient_id: string;
  credentials_registered: boolean;
  brand_logo: string;
  constructor(
    private dashboardSerice: DashboardService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router
  ) {
    // if (
    //   !(localStorage.getItem('patient_id') || localStorage.getItem('user_id'))
    // ) {
    //   this.router.navigate(['/home']);
    // }
    // if (localStorage.getItem('brand_logo')) {
    //   this.brand_logo = localStorage.getItem('brand_logo');
    // } else {
      this.brand_logo = 'assets/icons/Verahealth-Logo.png';
    // }
    if (localStorage.getItem('token')) {
      this.getNotifications();
    }
    this.form = fb.group(
      {
        username: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
        ]),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
        ]),
        confirm_password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
        ]),
      },
      { validators: this.checkPasswords }
    );
  }

  ngOnInit(): void {
    if (localStorage.getItem('client_id')) {
      this.client_id = localStorage.getItem('client_id');
    }
    if (localStorage.getItem('patient_id')) {
      this.patient_id = localStorage.getItem('patient_id');
    }
    if (localStorage.getItem('credentials_registered')) {
      this.credentials_registered = JSON.parse(
        localStorage.getItem('credentials_registered')
      );

      this.showSwitchButton = true;

      // if (!this.credentials_registered) {
      //   this.showSwitchButton = true;
      // }
    }
  }

  checkPasswords(form: FormGroup) {
    return form.controls['password'].value ===
      form.controls['confirm_password'].value
      ? null
      : { mismatch: true };
  }

  get f() {
    return this.form.controls;
  }

  renderRegisterScreen() {
    this.registerCredentials = true;
  }

  register() {
    this.spinner.show();
    const data = {
      username: this.username,
      password: this.password,
    };
    this.dashboardSerice
      .updateCredentials(this.patient_id, this.client_id, data)
      .subscribe(
        (resp) => {
          this.spinner.hide();
          this.registerCredentials = false;
          this.showSwitchButton = true;
          this.toastr.success(
            'Account credentials updated & can be used to log in now.'
          );
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(error.error.message);
          console.log(error);
        }
      );
  }

  logout() {
    localStorage.clear();
  }

  getNotifications() {
    this.dashboardSerice.getNotifications().subscribe(
      (response) => {
        this.response = response.data;
        if (response.data) {
          this.response = response.data;
          localStorage.setItem(
            'clientObj',
            JSON.stringify(response.data.client)
          );
          localStorage.setItem(
            'providerObj',
            JSON.stringify(response.data.provider)
          );
        }
      },
      (error) => {
        this.toastr.error(error.error.message);
        console.log(error);
      }
    );
  }

  togglePassword() {
    this.showPass = !this.showPass;
  }

  toggleConfirmPassword() {
    this.showConfirmPass = !this.showConfirmPass;
  }
}
