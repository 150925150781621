import {
  Component,
  OnInit,
  ViewChild,
  Directive,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TasksService } from '../services/tasks.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SignaturePad } from 'angular2-signaturepad';
import { sanitizeIdentifier } from '@angular/compiler';
import emailMask from 'text-mask-addons/dist/emailMask';
import * as moment from 'moment';

@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.css'],
})
export class TaskDetailsComponent implements OnInit {
  private unsubscribeSource: Subject<any>;
  task_id: any;
  patient_id: any;
  details: any;
  file: any;
  first_name: string;
  last_name: string;
  med_rec_nbr: string;
  date_of_birth: any;
  formArray = [];
  subFieldFormArray = [];
  patientData = {};
  signatureFile = []; //this should be an array
  styleArray = [];
  styles: any;
  count = 0;
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  @ViewChild('form', { read: NgForm }) form: any;
  @ViewChildren(SignaturePad) signaturePad: QueryList<SignaturePad>;

  constructor(
    private route: ActivatedRoute,
    private tasksService: TasksService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    if (localStorage.getItem('first_name')) {
      if (localStorage.getItem('first_name').length > 0) {
        this.first_name = localStorage.getItem('first_name');
        this.patientData['first_name'] = localStorage.getItem('first_name');
      }
    }
    if (localStorage.getItem('last_name')) {
      if (localStorage.getItem('last_name').length > 0) {
        this.last_name = localStorage.getItem('last_name');
        this.patientData['last_name'] = localStorage.getItem('last_name');
      }
    }
    if (localStorage.getItem('med_rec_nbr')) {
      if (localStorage.getItem('med_rec_nbr').length > 0) {
        this.med_rec_nbr = localStorage.getItem('med_rec_nbr');
        this.patientData['med_rec_nbr'] = localStorage.getItem('med_rec_nbr');
      }
    }
    if (localStorage.getItem('date_of_birth')) {
      if (localStorage.getItem('date_of_birth').length > 0) {
        this.date_of_birth = moment(
          localStorage.getItem('date_of_birth')
        ).format('YYYY/MM/DD');
        this.patientData['date_of_birth'] = moment(
          localStorage.getItem('date_of_birth')
        ).format('YYYY/MM/DD');
      }
    }
  }

  ngOnInit(): void {
    this.unsubscribeSource = new Subject<any>();
    this.initSubscription();
  }

  initSubscription() {
    this.subscribeToQueryParams();
  }

  private subscribeToQueryParams() {
    // this.route.queryParams
    //   .pipe(takeUntil(this.unsubscribeSource))
    //   .subscribe((params) => {
    //     if (params.task_id) {
    //       this.task_id = params.task_id;
    //       this.getTaskDetails(params.task_id);
    //     }
    //   });
    this.patient_id = this.route.snapshot.params['patient_id'];
    this.task_id = this.route.snapshot.params['task_id'];
    this.getTaskDetails(this.task_id, this.patient_id);
  }

  private getTaskDetails(task_id, patient_id) {
    this.spinner.show();
    this.tasksService.getTasksDetails(task_id, patient_id).subscribe(
      (response) => {
        this.details = response.data;
        if (this.details.clientForm && this.details.clientForm.fields) {
          this.details.clientForm.fields.forEach((field) => {
            if (field.field_type === 8) {
              field.rows = 1;
            }
            field.sub_fields.forEach((sub) => {
              if (sub.field_type === 8) {
                sub.rows = 1;
              }
            });
          });
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error.error.message);
        console.log(error);
      }
    );
  }

  async saveForm(event: any) {
    this.spinner.show();
    const form_responses = await this.uploadImages(event.form_responses);

    /*  OLD CODE 

    //---for datagrid
    let chkResults = formFields.filter((x) => x.field_type === 8)
    if (chkResults.length > 0) {
      chkResults.forEach((element) => {
        let records = formArray.filter((x) => x.field_id === element.id)
        formArray = formArray.filter((x) => x.field_id !== element.id + '')
        if (records.length > 0) {
          let rows = element.rows - 1
          while (rows >= 0) {
            let newResponse = []
            element.options.forEach((option) => {
              const matchingRecord = records[0].options.filter(
                (x) => x.id === option.id && x.row === rows,
              )
              if (matchingRecord.length > 0 && matchingRecord[0].option) {
                newResponse.push(matchingRecord[0].option)
              } else if (
                records[0].options.filter((x) => x.row === rows).length > 0
              ) {
                newResponse.push('')
              }
            })
            rows = rows - 1
            let responseStr = ''
            debugger
            console.log('new response', newResponse)
            if (newResponse.length > 0) {
              newResponse.forEach((elem) => {
                responseStr = responseStr.concat(elem + '||c||')
              })
              formArray.push({ field_id: element.id, response: responseStr })
            }
          }
        }
      })
    }

    //---for checkboxes
    chkResults = formFields.filter((x) => x.field_type === 5)
    if (chkResults.length > 0) {
      chkResults.forEach((element) => {
        let records = formArray.filter((x) => x.field_id === element.id)
        formArray = formArray.filter((x) => x.field_id !== element.id + '')
        if (records.length > 0) {
          records[0].options.forEach((opt) => {
            formArray.push({ field_id: element.id, response: opt.option })
          })
        }
      })
    }
    //---follow up
    chkResults = formFields.filter((x) => x.field_type === 9)
    chkResults.forEach((ele) => {
      if (ele.showFields) {
        let grd = ele.sub_fields.filter((x) => x.field_type === 8)
        grd.forEach((element) => {
          let records = subFieldFormArray.filter((x) => x.id === element.id)
          subFieldFormArray = subFieldFormArray.filter(
            (x) => x.id !== element.id + '',
          )
          if (records.length > 0) {
            let rows = element.rows - 1
            while (rows >= 0) {
              let newResponse = []
              element.options.forEach((option) => {
                const matchingRecord = records[0].options.filter(
                  (x) => x.id === option.id && x.row === rows,
                )
                if (matchingRecord.length > 0 && matchingRecord[0].option) {
                  newResponse.push(matchingRecord[0].option)
                } else if (
                  records[0].options.filter((x) => x.row === rows).length > 0
                ) {
                  newResponse.push('')
                }
              })
              rows = rows - 1
              let responseStr = ''
              if (newResponse.length > 0) {
                newResponse.forEach((elem) => {
                  responseStr = responseStr.concat(elem + '||c||')
                })
                formArray.push({
                  parent_field_id: element.field_id,
                  field_id: element.id,
                  response: responseStr,
                })
              }
            }
          }
        })

        let chkbox = ele.sub_fields.filter((x) => x.field_type === 5)
        if (chkbox.length > 0) {
          chkbox.forEach((element) => {
            let records = subFieldFormArray.filter((x) => x.id === element.id)
            subFieldFormArray = subFieldFormArray.filter(
              (x) => x.id !== element.id + '',
            )
            if (records.length > 0) {
              records[0].options.forEach((opt) => {
                formArray.push({
                  parent_field_id: element.field_id,
                  field_id: element.id,
                  response: opt.option,
                })
              })
            }
          })
        }
        ele.sub_fields.forEach((type) => {
          if (type.field_type !== 5 && type.field_type !== 8) {
            let record = subFieldFormArray.find((x) => x.id === type.id)
            if (record) {
              formArray.push({
                parent_field_id: record.field_id,
                field_id: record.id,
                response: record.response,
              })
            }
          }
        })
      }
    })

    this.formArray.forEach((element) => {
      formArray.push({ field_id: element.field_id, response: element.response })
    })
    this.subFieldFormArray.forEach((element) => {
      formArray.push({
        parent_field_id: element.field_id,
        field_id: element.id,
        response: element.response,
      })
    }) */

    if (form_responses && form_responses.length) {
      let form = { form_id: this.details.form_id, form_data: form_responses };
      this.tasksService.submitTask(this.task_id, form).subscribe(
        (response) => {
          this.spinner.hide();
          if (response.code === 200) {
            this.toastr.success('Your form has been submitted successfully');
            setTimeout(() => {
              window.location.href = '/dashboard';
            }, 2000);
          }
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(error.error.message);
          console.log(error);
        }
      );
    }
  }

  async uploadImages(responses: any) {
    for (const response of responses) {
      // const fileList: FileList = file;
      if (response.field_type === 4 || response.field_type === 6) {
        // const file: File = fileList;
        const formData: FormData = new FormData();
        formData.append('media', response.response);
        await this.tasksService
          .uploadImage(formData, this.task_id)
          .then((resp) => {
            if (resp.data) {
              response.response = resp.data.url;
            }
          })
          .catch((err) => {
            this.spinner.hide();
            this.toastr.error(err);
          });
      }
    }
    return responses;
  }
}
