<div class="screen screen-8">
  <div
    class="order-otr"
    (click)="goBack()"
    style="cursor: pointer; margin: 0 0 24px 0"
  >
    <a>
      <svg
        class="icon"
        width="24"
        height="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.47 17.78a.75.75 0 101.06-1.06l-3.97-3.97h8.69a.75.75 0 000-1.5H8.56l3.97-3.97a.75.75 0 00-1.06-1.06l-5.25 5.25a.747.747 0 00-.215.523v.004a.748.748 0 00.22.529l5.25 5.25z"
          fill="#252F5A"
        />
      </svg>
    </a>
    <p class="heading">Documents</p>
  </div>
  <!-- Toggle switch for filtering -->
  <div class="switch-container">
    <span class="toggle-label">
      {{ filterType === 'withOrder' ? 'Ordered Documents' : 'Attachments Only' }}
    </span>
    <label class="switch">
      <input type="checkbox" (change)="toggleAttachments()" [checked]="filterType === 'withOrder'" />
      <span class="slider round"></span>
    </label>
  </div>

  <div class="table-data__wrapper">
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Category</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let document of documentsTableData">
          <td>{{ document.file_name }}</td>
          <td>{{ document.category }}</td>
          <td>
            <button
            (click)="previewDocument(document)"
            style="cursor: pointer; background: none; border: none; margin-right: 8px;"
            ngbTooltip="Preview"
          >
            <i
              class="fa fa-print"
              style="color: #073786; font-size: 20px;"
              aria-hidden="true"
            ></i>
          </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="documentsTableData.length === 0">
    <p>No documents found.</p>
  </div>
</div>
