<div class="screen screen-6">
  <div class="order-otr">
    <a routerLink="/dashboard" (click)="reset()">
      <svg
        class="icon"
        width="24"
        height="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.47 17.78a.75.75 0 101.06-1.06l-3.97-3.97h8.69a.75.75 0 000-1.5H8.56l3.97-3.97a.75.75 0 00-1.06-1.06l-5.25 5.25c-.002 0-.003.003-.005.005a.747.747 0 00-.215.523v.004a.748.748 0 00.22.529m5.25 5.25l-5.25-5.25z"
          fill="#252F5A"
        />
      </svg>
    </a>
    <p class="heading">ORDER Data</p>
  </div>
  <div *ngIf="recordIndex">
    <owl-carousel-o
      [options]="customOptions"
      (translated)="getData($event)"
      #owlCar
    >
      <ng-container *ngFor="let record of recordIndex; let i = index">
        <ng-template carouselSlide [id]="i">
          <div class="client-otr">
            <div class="icon-otr">
              <span class="circle"></span>
              <svg
                class="location-icon"
                width="20"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.343 4.343A8 8 0 0118 10c0 3.098-2.016 6.104-4.226 8.437A29.412 29.412 0 0110 21.773a29.418 29.418 0 01-3.774-3.335C4.016 16.103 2 13.097 2 10a8 8 0 012.343-5.657zm5.102 19.489L10 23l-.555.832a1 1 0 001.11 0L10 23l.555.832h.001l.002-.002.007-.005.023-.015.082-.057c.072-.05.174-.12.302-.214a31.433 31.433 0 004.254-3.727C17.516 17.397 20 13.903 20 10a10 10 0 00-20 0c0 3.902 2.484 7.396 4.774 9.813a31.428 31.428 0 004.254 3.726 19.098 19.098 0 00.384.27l.023.016.007.005.002.001zM8 10a2 2 0 114 0 2 2 0 01-4 0zm2-4a4 4 0 100 8 4 4 0 000-8z"
                  fill="#fff"
                />
              </svg>
            </div>
            <ul class="text-otr">
              <li
                class="text"
                *ngIf="arrayOfUsers[i] && arrayOfUsers[i].clientResponse"
              >
                {{ arrayOfUsers[i].clientResponse.name }}
              </li>
              <li
                class="text"
                *ngIf="arrayOfUsers[i] && arrayOfUsers[i].clientResponse"
              >
                {{ arrayOfUsers[i].clientResponse.address }}
              </li>
              <li
                class="text"
                *ngIf="arrayOfUsers[i] && arrayOfUsers[i].clientResponse"
              >
                <address class="text address">
                  {{ arrayOfUsers[i].clientResponse.city }},
                  {{ arrayOfUsers[i].clientResponse.state }}
                  {{ arrayOfUsers[i].clientResponse.zip }}
                </address>
              </li>
              <li
                class="text last-text"
                *ngIf="arrayOfUsers[i] && arrayOfUsers[i].clientResponse"
              >
                <a href="tel: {{ arrayOfUsers[i].clientResponse.phone }}">
                  {{ arrayOfUsers[i].clientResponse.phone }}</a
                >
              </li>
            </ul>
          </div>
          <div class="order-main">
            <span>
              <span class="heading">Orders</span>
              <span class="hyperLink">
                <a
                  (click)="viewOrders(arrayOfUsers[i])"
                  *ngIf="
                    arrayOfUsers[i] &&
                    arrayOfUsers[i].isPastOrder &&
                    arrayOfUsers[i].pastOrdersResponse?.length > 0
                  "
                  >View Past Orders</a
                >
                <a
                  (click)="viewOrders(arrayOfUsers[i])"
                  *ngIf="
                    arrayOfUsers[i] &&
                    !arrayOfUsers[i].isPastOrder &&
                    arrayOfUsers[i].pastOrdersResponse?.length > 0 &&
                    arrayOfUsers[i].ordersResponse.length > 0
                  "
                  >View Latest Orders</a
                >
              </span>
            </span>

            <ul
              class="order-list-otr"
              *ngIf="arrayOfUsers[i] && arrayOfUsers[i].ordersResponse"
            >
              <li
                class="order-list-inr"
                *ngFor="let order of arrayOfUsers[i].ordersResponse"
              >
                <a
                  class="order-list-single hand-cursor"
                  (click)="navigateOrderDetails(order.order_id)"
                >
                  <div class="order">
                    <span class="circle"></span>
                    <p class="desc list-paragraph">{{ order.order_summary }}</p>
                  </div>
                  <span class="icon">
                    <svg
                      class="arrow-icon"
                      width="16"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.667 4l-.94.94L8.78 8l-3.053 3.06.94.94 4-4-4-4z"
                        fill="#252F5A"
                      />
                    </svg>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
    <p class="power-footer">Powered By VYTLsft</p>
  </div>
</div>
