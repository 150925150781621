<div class="screen screen-6">
    <div class="order-otr">
        <a href="/dashboard">
            <svg class="icon" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M11.47 17.78a.75.75 0 101.06-1.06l-3.97-3.97h8.69a.75.75 0 000-1.5H8.56l3.97-3.97a.75.75 0 00-1.06-1.06l-5.25 5.25c-.002 0-.003.003-.005.005a.747.747 0 00-.215.523v.004a.748.748 0 00.22.529m5.25 5.25l-5.25-5.25z"
                    fill="#252F5A" />
            </svg>
        </a>
        <p class="heading">Contact</p>
    </div>

    <div style="text-align:center" *ngIf="providerList && providerList.length===0">
        <img src="../../assets/img/no-result-search.png" style="width: 300px;">
        <p class="no-data">Everything seems to be working correctly, there is just nothing to see here</p>
    </div>
    <ng-container *ngFor="let list of providerList">
        <div class="client-otr">
            <div class="icon-otr">
                <span class="circle"></span>
                <svg class="location-icon" width="20" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M4.343 4.343A8 8 0 0118 10c0 3.098-2.016 6.104-4.226 8.437A29.412 29.412 0 0110 21.773a29.418 29.418 0 01-3.774-3.335C4.016 16.103 2 13.097 2 10a8 8 0 012.343-5.657zm5.102 19.489L10 23l-.555.832a1 1 0 001.11 0L10 23l.555.832h.001l.002-.002.007-.005.023-.015.082-.057c.072-.05.174-.12.302-.214a31.433 31.433 0 004.254-3.727C17.516 17.397 20 13.903 20 10a10 10 0 00-20 0c0 3.902 2.484 7.396 4.774 9.813a31.428 31.428 0 004.254 3.726 19.098 19.098 0 00.384.27l.023.016.007.005.002.001zM8 10a2 2 0 114 0 2 2 0 01-4 0zm2-4a4 4 0 100 8 4 4 0 000-8z"
                        fill="#fff" />
                </svg>
            </div>
            <ul class="text-otr">
                <li class="text" *ngIf="list.client && list.client.name">{{list.client.name}}</li>
                <li class="text" *ngIf="list.practice_id">{{list.practice_id}}</li>
                <li class="text" *ngIf="this.providerList">{{list.client.address}}</li>
                <li class="text" *ngIf="this.providerList">
                    <address class="text address">
                        {{list.client.city}}, {{list.client.state}} {{list.client.zip}}

                    </address>
                </li>
                <li class="text last-text" *ngIf="this.providerList">
                    <a href="tel: {{list.client.phone}}"> {{list.client.phone}}</a>
                </li>
            </ul>
        </div>
        <div class="contact-btn" style="padding:0 0 0 0 !important;">
            <button class="btn-contact hand-cursor" type="button"
                (click)="navigateToConversationList(list.practice_id)">Contact My Provider</button>
        </div>
    </ng-container>

</div>