<!--==========================
           Screen-1 End Here
    ===========================-->
<div class="screen screen-1">
  <div class="logo">
    <img class="logo-img" src="{{ this.brand_logo }}" alt="logo" />
  </div>
  <div class="action-otr">
    <button
      *ngIf="this.showTermAndCondition"
      class="btn-term hand-cursor"
      (click)="navigateTermOfUse()"
    >
      VIEW TERMS OF USE TO CONTINUE
    </button>
  </div>
  <p class="heading">
    Powered By <br />
    VYTLsft
  </p>
  <p class="heading-2">VYTLsft, LLC</p>
  <p class="desc">
    159 West Broadway. Suite 200-113 <br />
    Salt Lake City, UT 84101
  </p>
</div>

<!--==========================
           Screen-1 End Here
    ===========================-->
