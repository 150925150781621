<div class="screen screen-3">
  <div class="logo">
    <img class="logo-img" src="assets/img/logo.png" alt="logo" />
  </div>
  <p class="text desc">
    Help us secure your account, we will confirm this information with your
    healthcare provider. <br />
    Once confirmed you can create an account.
  </p>

  <form [formGroup]="form" (ngSubmit)="signUp()">
    <div class="input-otr" *ngIf="this.userType === 'signup'">
      <p class="input-text-1">
        Last name on file with
        <span *ngIf="client_name">{{ this.client_name }}</span>
      </p>
      <div
        *ngIf="f.last_name.touched && f.last_name.invalid"
        class="alert alert-danger"
      >
        <div class="error-text" *ngIf="f.last_name.errors.required">
          Invalid Last Name
        </div>
      </div>
      <input
        class="input"
        (focus)="onFocus()"
        (blur)="removeLastNametool()"
        type="text"
        name="last_name"
        id="last_name"
        [(ngModel)]="inputModel.last_name"
        formControlName="last_name"
      />
      <div *ngIf="LastNameToolTip" class="tooltip">
        <span class="tooltiptext">
          <div style="margin: 5px">
            <p class="input-text-2" style="color: white">
              Please enter your Last Name e.g Name : John Mary , Mary will be
              the last name
            </p>
          </div>
        </span>
      </div>
    </div>
    <div class="input-otr" *ngIf="this.userType === 'linkuser'">
      <p class="input-text-1">
        Cell phone on file with
        <span *ngIf="client_name">{{ this.client_name }}</span>
      </p>
      <div
        *ngIf="f.cell_phone.touched && f.cell_phone.invalid"
        class="alert alert-danger"
      >
        <div class="error-text" *ngIf="f.cell_phone.errors.required">
          Invalid Cell Phone
        </div>
      </div>
      <input
        (focus)="onFocusCellPhone()"
        (blur)="removeCellPhoneToolTip()"
        class="input"
        type="text"
        name="cell_phone"
        id="cell_phone"
        [(ngModel)]="inputModel.cell_phone"
        formControlName="cell_phone"
      />
    </div>
    <div class="input-otr">
      <p class="input-text-2">
        Date of birth on file with
        <span *ngIf="client_name">{{ this.client_name }}</span>
      </p>
      <div class="alert alert-danger">
        <div class="error-text" *ngIf="!dobFlag && dateOfBirth.touched">
          Invalid Date of Birth
        </div>
      </div>

      <mat-form-field
        appearance="none"
        style="width: 100%; border-bottom: 1px solid #ced4da"
      >
        <input
          class="date-of-birth"
          matInput
          [textMask]="mask"
          [matDatepicker]="picker2"
          [min]="minDate"
          (click)="onClick($event)"
          [max]="maxDate"
          (dateChange)="onDateChange($event)"
          (input)="onInputChange($event.target.value)"
          placeholder="Choose a date"
          #dateOfBirth
        />
        <mat-datepicker-toggle matSuffix [for]="picker2">
        </mat-datepicker-toggle>
        <mat-datepicker
          style="border-bottom: 1px solid #ced4da"
          #picker2
        ></mat-datepicker>
      </mat-form-field>
      <br />
    </div>
    <div class="enable-otr">&nbsp;</div>
    <div class="action-otr login-btn" style="padding-top: 22px !important">
      <button
        id="signup-btn"
        [disabled]="form.invalid || !dobFlag"
        class="btn-login hand-cursor"
        type="submit"
      >
        Verify
      </button>
    </div>
  </form>
  <p class="power-footer">Powered By VYTLsft</p>
</div>
