import {
  Component,
  OnInit,
} from '@angular/core';
import { OrderService } from '../services/order.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-documents',
  templateUrl: './my-documents.component.html',
  styleUrls: ['./my-documents.component.css'],
})
export class MyDocumentsComponent implements OnInit {
  documentsTableData: any[] = [];
  filterType: string = 'withOrder';

  constructor(
    private orderService: OrderService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    const patientId = localStorage.getItem('patient_id');
    if (patientId) {
      this.getDocuments();
    }
  }

  getDocuments(): void {    
    this.orderService.getPatientDocuments(this.filterType).subscribe({
      next: (documents) => {
        if (Array.isArray(documents.data)) {
          this.documentsTableData = documents.data;
        } else {
          console.error("Expected an array, but received:", documents.data);
        }
        this.spinner.hide();
      },
      error: (error) => {
        console.error("Failed to fetch documents:", error);
        this.spinner.hide();
      }
    });
  }

  toggleAttachments(): void {
    this.filterType = this.filterType === 'withOrder' ? 'withoutOrder' : 'withOrder';
    this.getDocuments();
  }

  previewDocument(doc: any): void {
    window.open(doc.file_url, '_blank');
  }

  goBack(): void {
    this.router.navigate(['/dashboard']);
  }
}