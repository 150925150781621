<div class="screen screen-9">
  <div class="order-otr">
    <a routerLink="/dashboard" (click)="reset()">
      <svg
        class="icon"
        width="24"
        height="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.47 17.78a.75.75 0 101.06-1.06l-3.97-3.97h8.69a.75.75 0 000-1.5H8.56l3.97-3.97a.75.75 0 00-1.06-1.06l-5.25 5.25c-.002 0-.003.003-.005.005a.747.747 0 00-.215.523v.004a.748.748 0 00.22.529m5.25 5.25l-5.25-5.25z"
          fill="#252F5A"
        />
      </svg>
    </a>
  </div>
  <div *ngIf="recordIndex">
  <owl-carousel-o [options]="customOptions" (translated)="getData($event)"  #owlCar>
    <ng-container *ngFor="let record of recordIndex; let j = index">
      <ng-template carouselSlide  [id]="j">
        <div class="screen-6">
        <div class="client-otr">
          <div class="icon-otr">
            <span class="circle"></span>
            <svg
              class="location-icon"
              width="20"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.343 4.343A8 8 0 0118 10c0 3.098-2.016 6.104-4.226 8.437A29.412 29.412 0 0110 21.773a29.418 29.418 0 01-3.774-3.335C4.016 16.103 2 13.097 2 10a8 8 0 012.343-5.657zm5.102 19.489L10 23l-.555.832a1 1 0 001.11 0L10 23l.555.832h.001l.002-.002.007-.005.023-.015.082-.057c.072-.05.174-.12.302-.214a31.433 31.433 0 004.254-3.727C17.516 17.397 20 13.903 20 10a10 10 0 00-20 0c0 3.902 2.484 7.396 4.774 9.813a31.428 31.428 0 004.254 3.726 19.098 19.098 0 00.384.27l.023.016.007.005.002.001zM8 10a2 2 0 114 0 2 2 0 01-4 0zm2-4a4 4 0 100 8 4 4 0 000-8z"
                fill="#fff"
              />
            </svg>
          </div>
          <ul class="text-otr">
            <li
              class="text"
              *ngIf="arrayOfUsers[j]"
            >
              {{ arrayOfUsers[j].name }}
            </li>
            <li
              class="text"
              *ngIf="arrayOfUsers[j]"
            >
              {{ arrayOfUsers[j].address }}
            </li>
            <li
              class="text"
              *ngIf="arrayOfUsers[j]"
            >
              <address class="text address">
                {{ arrayOfUsers[j].city }},
                {{ arrayOfUsers[j].state }}
                {{ arrayOfUsers[j].zip }}
              </address>
            </li>
            <li
              class="text last-text"
              *ngIf="arrayOfUsers[j]"
            >
              <a href="tel: {{ arrayOfUsers[j].phone }}">
                {{ arrayOfUsers[j].phone }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div class="order-otr" style="margin-top: px">
          <p class="heading">APPOINTMENTS</p>
        </div>
        <div *ngFor="let patientData of arrayOfpatientAppointments[j]">
          <div class="appointment-otr">
            <div class="header">
              <div class="icon-otr">
                <span class="circle">
                  <img src="assets/icons/tick.png" />
                </span>
              </div>
              <div class="info" *ngIf="patientData && patientData.event_type">
                <p class="type">{{ patientData.event_type }}</p>
                <p class="doc">{{ patientData.provider }}</p>
              </div>
            </div>
            <hr class="divider" />
            <div class="section">
              <p class="label">Scheduled Date</p>
              <p class="value" *ngIf="patientData && patientData.appointment_date">
                {{ patientData.appointment_date }}
              </p>
            </div>
            <hr class="divider" />
            <div class="section">
              <p class="label">Facility</p>
              <p class="value" *ngIf="patientData && patientData.location">
                {{ patientData.location }}
              </p>
            </div>
            <hr class="divider" />
            <div class="section">
              <p class="label">Scheduled Time</p>
              <p class="value" *ngIf="patientData && patientData.appointment_time">
                {{ patientData.appointment_time}}
              </p>
            </div>
            <div class="section" *ngIf="!patientData.is_confirmed" style="margin-bottom: 25px;">
              <hr class="dividerConfirm" />
              <p class="label" style="margin-top: 5px;">Confirm Appointment ?</p>
              <p class="value" *ngIf="!patientData.is_confirmed">
                <button class="button" (click)="confirmAppointment(patientData.appointment_id)">Confirm</button>
              </p>
            </div>
            <hr class="divider" />
          </div>
        </div>
      </div>
        <div>
          <div *ngFor="let data of arrayOfAppoinments[j]">
            <div class="appointment-otr">
              <div class="header">
                <div class="icon-otr">
                  <span class="circle">
                    <img src="assets/icons/tick.png" />
                  </span>
                </div>
                <div class="info" *ngIf="data && data.order_summary">
                  <p class="type">{{ data.order_summary }}</p>
                  <p class="doc">{{ data.provider }}</p>
                </div>
              </div>
              <hr class="divider" />
              <div class="section">
                <p class="label">Scheduled Date</p>
                <p class="value" *ngIf="data && data.scheduled_date">
                  {{ data.scheduled_date }}
                </p>
              </div>
              <hr class="divider" />
              <div class="section">
                <p class="label">Facility</p>
                <p class="value" *ngIf="data && data.order_facility">
                  {{ data.order_facility }}
                </p>
              </div>
              <hr class="divider" />
              <p class="heading">Appointments</p>
              <ng-container *ngIf="data">
                <div
                  *ngFor="
                    let appointment of data.order_appointments;
                    let last = last
                  "
                >
                  <div class="event-section">
                    <p class="label">{{ appointment.event_type }}</p>
                    <p class="value">
                      {{ appointment.appointment_date}}
                    </p>
                  </div>
                  <div
                    class="event-section"
                    *ngIf="
                      (appointment.appointment_time) !== '00:00'
                    "
                  >
                    <p class="label">Time</p>
                    <p class="value">
                      {{ appointment.appointment_time}}
                    </p>
                  </div>
                  <hr align="center" width="50%" class="mini-divider" />
                  <div class="location-section">
                    <p class="label">{{ appointment.location }}</p>
                  </div>
                  <hr *ngIf="!last" class="divider" />
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <button class="contact-btn" routerLink="/conversation-list">CONTACT OFFICE</button>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
  <p class="power-footer">Powered By VYTLsft</p>
  </div>
</div>
