import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(private httpClient: HttpClient) {

  }

  getAllClients() {
    let header = new HttpHeaders().set(
      "Authorization",
      localStorage.getItem("token")
    );

    return this.httpClient.get<any>(`${environment.URL}contact-provider`, { headers: header });
  }
}
