import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { mfaModel } from '../models/mfa';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.css']
})
export class MFAComponent implements OnInit {
  pinOne: string = '';
  pinTwo: string = '';
  pinThree: string = '';
  pinFour: string = '';
  pinFive: string = '';
  pinSix: string = '';
  phone_digit
  inputModel: mfaModel;
  form: FormGroup = new FormGroup({});
  @ViewChild('pinOneElement') pinOneElement: ElementRef;
  //public mask = [/\d/]
  public mask = [/\d/]

  constructor(private loginService: LoginService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService) {
    this.inputModel = new mfaModel();
    // old implementation
    // if (localStorage.getItem('cell_phone_end')) {
    //   this.phone_digit = localStorage.getItem('cell_phone_end');
    // }
    if (localStorage.getItem('client_id')) {
      this.inputModel.client_id = localStorage.getItem('client_id');
    }
    if (localStorage.getItem('patient_id')) {
      this.inputModel.patient_id = localStorage.getItem('patient_id');
    }
    if (localStorage.getItem('user_id')) {
      this.inputModel.user_id = localStorage.getItem('user_id');
    }
    this.form = fb.group({
      pinOne: ['', [Validators.required]],
      pinTwo: ['', [Validators.required]],
      pinThree: ['', [Validators.required]],
      pinFour: ['', [Validators.required]],
      pinFive: ['', [Validators.required]],
      pinSix: ['', [Validators.required]],

    })
  }


  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
  }

  resendCode() {
    this.spinner.show();
    this.loginService
      .resendQuickCode(
        this.inputModel
      )
      .subscribe((response) => {
        this.spinner.hide();
        // window.location.href = '/dashboard';
      }, (error) => {
        this.spinner.hide();

        if (error.error) {
          if (error.error.code === 303) {
            if (error.error.data && error.error.data.cell_phone_end) {
              localStorage.setItem('cell_phone_end', error.error.data.cell_phone_end);
            }
            this.refreshPin();
            this.toastr.success("Code resent on your registered phone");

          } else
            this.toastr.error(error.error.message);
        } else {
          this.toastr.error(error);
        }
      });
  }

  verifyCode() {
    this.spinner.show();
    this.inputModel.mfa_code = this.pinOne + this.pinTwo + this.pinThree + this.pinFour + this.pinFive + this.pinSix;
    this.loginService
      .verifyQuickCode(
        this.inputModel
      )
      .subscribe((response) => {
        if (response.data.user_ids.length) {
          localStorage.setItem('client_id', response.data.user_ids[0].client_id);
          localStorage.setItem('patient_id', response.data.user_ids[0].patient_id);
        }
    
        if (response.data.token) {
          localStorage.setItem('token', response.data.token);
        }
        if (response.data.patient_name) {
          localStorage.setItem('patient_name', response.data.patient_name);
        }
        if (response.data.first_name) {
          localStorage.setItem('first_name', response.data.first_name);
        }
        if (response.data.last_name) {
          localStorage.setItem('last_name', response.data.last_name);
        }
        if (response.data.date_of_birth) {
          localStorage.setItem('date_of_birth', response.data.date_of_birth);
        }
        if (response.data.med_rec_nbr) {
          localStorage.setItem('med_rec_nbr', response.data.med_rec_nbr);
        }
        if (response.data.user_ids) {
          localStorage.setItem(
            'multi_user_ids',
            JSON.stringify(response.data.user_ids)
          );
        }
        localStorage.setItem(
          'credentials_registered',
          response.data.credentials_registered
        );
        this.spinner.hide();
        this.router.navigate(['dashboard']);
      }, (error) => {
        this.spinner.hide();
        this.toastr.toastrConfig.timeOut = 3000;
        if (error.error) {
          if (error.error.code === 400) {
            this.refreshPin();

          }
          this.toastr.error(error.error.message);
        } else {
          // this.inputModel.pin_code = '';
          this.toastr.error(error);
        }
      });
  }

  refreshPin() {
    this.inputModel.mfa_code = '';
    this.pinOne = '';
    this.pinTwo = '';
    this.pinThree = '';
    this.pinFour = '';
    this.pinFive = '';
    this.pinSix = '';
    setTimeout(() => {
      this.pinOneElement.nativeElement.focus();
    }, 0);
  }

  onDigitInput(code, event) {
    let element;
    if ((event.srcElement.nextElementSibling && event.srcElement.nextElementSibling.value != '') || this.form.get(code).value != '') {
      if (event.code === 'ArrowRight') {
        element = event.srcElement.nextElementSibling;
      }
    }
    if (!isNaN(event.key)) {
      element = event.srcElement.nextElementSibling;
      this.form.get(code).setValue(event.key);
    }
    if (event.code === 'Backspace' || event.code === 'ArrowLeft') {
      element = event.srcElement.previousElementSibling;
    }
    if (element == null)
      return;
    else
      element.focus();
  }

}
