import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TermAndConditionModel } from '../models/termAndCondition';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class TermOfUseService {

    constructor(private httpClient: HttpClient) {

    }

    validateTermAndConditions(clientId: string, patient_id: string, practice_id?: string, user_id?: string): Observable<any> {
        let url = "";
        let header = {}

        if ((patient_id && patient_id !== "0") && (clientId && clientId !== "0")) {
            url = environment.VALIDATE_TERM_AND_Condition.replace(':patient_id', patient_id).replace(':client_id', clientId);
        }
        else if (user_id) {
            url = environment.VALIDATE_TERM_AND_Condition.replace(':patient_id', '0').replace(':client_id', '0');
            header = new HttpHeaders().set(
                "user_id",
                localStorage.getItem("user_id")
            );
        }

        if (url && url.length > 0) {
            if (practice_id) {
                url = url + '?practice_id=' + practice_id
            }
            return this.httpClient.get<any>(url, { headers: header })
                .pipe(map((response) => response || {}));
        }
    }

    acceptedTermAndConditions(model: any, userId?: string): Observable<any> {
        if (userId) {
            let header = new HttpHeaders().set(
                "user_id",
                localStorage.getItem("user_id")
            );
            return this.httpClient.post<any>(environment.ADD_TERM_AND_Condition, model, { headers: header })
                .pipe(map((response) => response || {}));
        } else
            return this.httpClient.post<any>(environment.ADD_TERM_AND_Condition, model)
                .pipe(map((response) => response || {}));
    }

    userAlreadyExist(patient_id: string, clientId: string): Observable<any> {
        let url = environment.URL;
        return this.httpClient.get<any>(url + 'account/patient/' + patient_id + '/client/' + clientId + '/exists')
            .pipe(map((response) => response || {}));
    }

}
