import { Platform } from '@angular/cdk/platform';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker, GoogleMap } from '@angular/google-maps';
import { MatDialog } from '@angular/material/dialog';
import { LocationsService } from '../services/locations.service';

@Component({
  selector: 'app-facility-locations',
  templateUrl: './facility-locations.component.html',
  styleUrls: ['./facility-locations.component.css'],
})
export class FacilityLocationsComponent implements OnInit, AfterViewInit {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  markers = [];
  zoom = 10;
  lat: any;
  lng: any;
  directions = false;
  formatted_address: any;
  image_url: any;
  options: google.maps.MapOptions = {
    zoom: 1,
    // disableDoubleClickZoom: true,
    mapTypeId: 'roadmap',
    maxZoom: 15,
    minZoom: 1,
  };
  center: google.maps.LatLngLiteral;
  // marker1 = { position: { lat: 36.837716, lng: -119.767435 }, options: { title: 'Dranesville Virginia United States' } };
  // markers = [this.marker1, this.marker2];
  constructor(
    public dialog: MatDialog,
    public locationService: LocationsService,
    public platform: Platform
  ) {}
  description: string;
  id: string;
  geocoder: google.maps.Geocoder;

  ngAfterViewInit() {
    this.geocoder = new google.maps.Geocoder();
    if (this.lat && this.lng) {
      this.getLocationAddresss();
    }
  }

  // getGeoAddress(request: google.maps.GeocoderRequest, callback): void {
  //   this.geocoder.geocode(request, function (results, status) {
  //     if (status === 'OK') {
  //       console.log(results[0]);
  //       callback(results);
  //     } else {
  //       alert('Geocode was not successful for the following reason: ' + status);
  //     }
  //   });
  // }

  getBounds(markers) {
    let north;
    let south;
    let east;
    let west;

    for (const marker of markers) {
      // set the coordinates to marker's lat and lng on the first run.
      // if the coordinates exist, get max or min depends on the coordinates.
      north =
        north !== undefined
          ? Math.max(north, marker.position.lat)
          : marker.position.lat;
      south =
        south !== undefined
          ? Math.min(south, marker.position.lat)
          : marker.position.lat;
      east =
        east !== undefined
          ? Math.max(east, marker.position.lng)
          : marker.position.lng;
      west =
        west !== undefined
          ? Math.min(west, marker.position.lng)
          : marker.position.lng;
    }

    const bounds = { north, south, east, west };
    return bounds;
  }

  getLocationAddresss() {
    let self = this;
    // this.locationService
    //   .getAddresses(encodeURIComponent(this.id))
    //   .subscribe((response) => {
    //     if (response && response.data) {
    // response.data.forEach(res => {
    //   if (res) {
    // this.getGeoAddress({ address: res.address_1 }, function (results) {
    self.map.center = { lat: this.lat, lng: this.lng };

    let marker = {
      position: { lat: this.lat, lng: this.lng },
      options: { title: this.formatted_address },
    };
    self.markers.push(marker);

    // self.center = {
    //   lat: this.lat,
    //   lng: this.lng,
    // }
    const bounds = self.getBounds(self.markers);
    self.map.googleMap.fitBounds(bounds);
    // });
    // }
    // });
    //   }
    // }, (error) => {
    //   // this.toastr.error(error.error.message);
    //   console.log(error)
    // });
  }
  zoomIn() {
    if (this.zoom < this.options.maxZoom) this.zoom++;
  }

  zoomOut() {
    if (this.zoom > this.options.minZoom) this.zoom--;
  }

  ngOnInit(): void {
    if ((this.lat && this.lng) === undefined) {
      this.directions = false;
    } else {
      this.directions = true;
    }
  }

  openGoogleMaps() {
    if (this.platform.ANDROID || this.platform.BLINK) {
      window.open(
        'https://google.com/maps/dir//' + this.lat + ',' + this.lng,
        '_blank'
      );
    } else if (this.platform.IOS || this.platform.SAFARI) {
      window.open(
        'https://maps.apple.com/?daddr=' + this.lat + ',' + this.lng,
        '_blank'
      );
    } else {
      window.open(
        'https://google.com/maps/dir//' + this.lat + ',' + this.lng,
        '_blank'
      );
    }
  }
}
