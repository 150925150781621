import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ConfirmedValidator } from '../confirmed.validator';
import { loginModel, signupModel } from '../models/login';
import { LoginService } from '../services/login.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { MatDatepickerInput } from '@angular/material/datepicker';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
})
export class SignUpComponent implements OnInit {
  userType: string;
  LastNameToolTip: boolean;
  CreatPinToolTip: boolean;
  ConfirmPinToolTip: boolean;
  dobFlag: boolean;
  pinVal: boolean;
  pinCnfrmVal: boolean;
  inputModel: signupModel;
  minDate: Date;
  maxDate: Date;
  client_name: string;
  showPass: boolean;
  showConfirmPass: boolean;
  form: FormGroup = new FormGroup({});
  @ViewChild('dateOfBirth') dateOfBirth: ElementRef;
  @ViewChild(MatDatepickerInput) datepickerInput: MatDatepickerInput<any>;

  constructor(
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.showPass = false;
    this.showConfirmPass = false;
    this.LastNameToolTip = false;
    this.CreatPinToolTip = false;
    this.ConfirmPinToolTip = false;
    // this.isMatch=true
    this.dobFlag = false;
    this.pinVal = false;
    this.pinCnfrmVal = false;
    this.client_name = '';
    this.minDate = new Date(1910, 4, 12);
    this.maxDate = new Date();
    this.inputModel = new signupModel();

    this.route.params.subscribe((params) => {
      this.userType = params['user_type'];
    });

    if (
      this.loginService.getDataForSignup().lastName === '' &&
      this.loginService.getDataForlinkUser().cellPhone === ''
    ) {
      this.router.navigate(['/home']);
    }

    if (localStorage.getItem('client_id')) {
      this.inputModel.client_id = localStorage.getItem('client_id');
    }
    if (localStorage.getItem('patient_id')) {
      this.inputModel.patient_id = localStorage.getItem('patient_id');
    }
    if (localStorage.getItem('client_name')) {
      this.client_name = localStorage.getItem('client_name');
    }

    this.form = fb.group(
      {
        pin_code: [''],
        cnfrm_pin_code: [''],
        username: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
        ]),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
        ]),
        confirm_password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
        ]),
      },
      { validators: this.checkPasswords }
    );
  }

  checkPasswords(form: FormGroup) {
    return form.controls['password'].value ===
      form.controls['confirm_password'].value
      ? null
      : { mismatch: true };
  }

  onFocusCreatPin() {
    this.CreatPinToolTip = true;
  }
  removeCreatPintool() {
    this.CreatPinToolTip = false;
  }
  onFocusConfirmPin() {
    this.ConfirmPinToolTip = true;
  }
  removeConfirmPintool() {
    this.ConfirmPinToolTip = false;
  }

  changePin(value) {
    if (value) {
      if (value.toString().length === 4) {
        this.pinVal = true;
      } else {
        this.pinVal = false;
      }
    }
  }

  changeCnfrmPin(value) {
    if (value) {
      if (value.toString().length === 4) {
        this.pinCnfrmVal = true;
      } else {
        this.pinCnfrmVal = false;
      }
    }
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {}

  register() {
    this.spinner.show();
    if (this.userType === 'linkuser') {
      this.inputModel.cell_phone =
        this.loginService.getDataForlinkUser().cellPhone;
      this.inputModel.date_of_birth =
        this.loginService.getDataForlinkUser().dob;
      this.loginService.linkAccount(this.inputModel).subscribe(
        (response) => {
          this.spinner.hide();
          if (response.data.token) {
            localStorage.setItem('token', response.data.token);
          }
          if (response.data.patient_name) {
            localStorage.setItem('patient_name', response.data.patient_name);
          }
          if (response.data.first_name) {
            localStorage.setItem('first_name', response.data.first_name);
          }
          if (response.data.last_name) {
            localStorage.setItem('last_name', response.data.last_name);
          }
          if (response.data.date_of_birth) {
            localStorage.setItem('date_of_birth', response.data.date_of_birth);
          }
          if (response.data.user_ids) {
            localStorage.setItem(
              'multi_user_ids',
              JSON.stringify(response.data.user_ids)
            );
          }
          if (response.data.med_rec_nbr) {
            localStorage.setItem('med_rec_nbr', response.data.med_rec_nbr);
          }
          this.router.navigate(['/dashboard']);
        },
        (error) => {
          this.spinner.hide();
          if (error && error.error.code === 303) {
            if (error.error.data && error.error.data.cell_phone_end) {
              localStorage.setItem(
                'cell_phone_end',
                error.error.data.cell_phone_end
              );
            }
            this.router.navigate(['/mfa']);
          } else if (error && error.error.code === 412) {
            this.addDefaultCredentials();
          } else {
            this.toastr.error(error.error.message);
            console.log(error);
          }
        }
      );
    }
    if (this.userType === 'signup') {
      this.loginService.signUp(this.inputModel).subscribe(
        (response) => {
          if (response.data.token) {
            localStorage.setItem('token', response.data.token);
          }
          if (response.data.patient_name) {
            localStorage.setItem('patient_name', response.data.patient_name);
          }
          if (response.data.first_name) {
            localStorage.setItem('first_name', response.data.first_name);
          }
          if (response.data.last_name) {
            localStorage.setItem('last_name', response.data.last_name);
          }
          if (response.data.date_of_birth) {
            localStorage.setItem('date_of_birth', response.data.date_of_birth);
          }
          if (response.data.user_ids) {
            localStorage.setItem(
              'multi_user_ids',
              JSON.stringify(response.data.user_ids)
            );
          }
          if (response.data.med_rec_nbr) {
            localStorage.setItem('med_rec_nbr', response.data.med_rec_nbr);
          }
          this.loginService.login(this.inputModel).subscribe(
            (response) => {
              this.spinner.hide();
              this.router.navigate(['dashboard']);
            },
            (error) => {
              this.spinner.hide();
              if (error && error.error.code === 303) {
                if (error.error.data && error.error.data.cell_phone_end) {
                  localStorage.setItem(
                    'cell_phone_end',
                    error.error.data.cell_phone_end
                  );
                }
                window.location.href = '/mfa';
              } else if (error && error.error.code === 412) {
                this.addDefaultCredentials();
              } else {
                this.toastr.error(error.error.message);
                console.log(error);
              }
            }
          );
        },
        (error) => {
          this.spinner.hide();
          if (error && error.error.code === 303) {
            if (error.error.data && error.error.data.cell_phone_end) {
              localStorage.setItem(
                'cell_phone_end',
                error.error.data.cell_phone_end
              );
            }
            window.location.href = '/mfa';
          } else if (error && error.error.code === 412) {
            this.addDefaultCredentials();
          } else {
            this.toastr.error(error.error.message);
            console.log(error);
          }
        }
      );
    }
  }

  addDefaultCredentials() {
    Swal.fire({
      title: 'Add Credentials',
      text: 'Would you like to make these credentials default for all your accounts?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      customClass: {
        popup: 'swal-resize'  // Use an object for customClass
      },
      confirmButtonText: 'Yes, Confirm it!',
    })
    .then((result) => {
      if (result.isConfirmed) {
        this.spinner.hide();

        const defaultCredentails = {
          cell_phone: this.inputModel.cell_phone,
          date_of_birth: this.inputModel.date_of_birth.replace(/-/g, ''),
          username: this.inputModel.username,
          password: this.inputModel.password,
        };

        this.loginService.addDefaultCredentials(defaultCredentails).subscribe(
          () => {
            this.spinner.show();
            this.register();
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(error);
          }
        );
      }
    });
  }

  togglePassword() {
    this.showPass = !this.showPass;
  }

  toggleConfirmPassword() {
    this.showConfirmPass = !this.showConfirmPass;
  }
}
