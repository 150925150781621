
export const environment = {
  production: true,
  APP_LINK: 'https://vytlsft.com/',
  STRIPE_KEY:
    'pk_live_51JRod3DYXkLuaaioxpFOUeB2xMhrUibkDuoAUnp1x1DsqmkGvhOazuJvGyYePG91We6AejPfdMQ9XJbacdp26Mwe00CWEcyyVM',
  DSN: 'https://fbe51f83a5394d37be6c3cc79c5b1984@o1001555.ingest.sentry.io/5963195',
  URL: 'https://random.westus.cloudapp.azure.com/',
  ADD_TERM_AND_Condition: 'https://random.westus.cloudapp.azure.com/terms',
  VALIDATE_TERM_AND_Condition:
    'https://random.westus.cloudapp.azure.com/terms/patient/:patient_id/client/:client_id',
  TALKJS_APP_ID: 'NmHNvw02',
  MARKETING_SITE_URL: 'https://www.vytl-sft.com/',
};
