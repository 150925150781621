<div class="screen screen-3">
  <div class="logo">
    <img class="logo-img" src="assets/img/logo.png" alt="logo" />
  </div>
  <h1 class="heading">Account Confirmed</h1>
  <p class="text desc">
    Help us secure your account, we will confirm this information with your
    healthcare provider. <br />
    Please create a username and password that will be used.
  </p>

  <form [formGroup]="form" (ngSubmit)="register()">
    <!-- <h1 class="heading">Create A New PIN</h1>
    <div class="input-otr-password">
      <p class="input-text-1">Create PIN (4 Digits)</p>
      <div
        *ngIf="f.pin_code.touched && f.pin_code.invalid"
        class="alert alert-danger"
      >
        <div class="error-text" *ngIf="f.pin_code.errors.required">
          Invalid Pin
        </div>
      </div>
      <input
        class="input-PIN"
        (focus)="onFocusCreatPin()"
        (blur)="removeCreatPintool()"
        type="text"
        name="pin_code"
        id="pin_code"
        maxlength="4"
        [(ngModel)]="inputModel.pin_code"
        formControlName="pin_code"
        (ngModelChange)="changePin($event)"
        onKeyPress="if(this.value.length==4) return false;"
      />
      <div *ngIf="CreatPinToolTip" class="tooltip">
        <span class="tooltiptext">
          <div style="margin: 3px">
            <p class="input-text-2" style="color: white">
              Please enter a 4 digit PIN code, this code will be your password
              while loging into this app so you need to remember it
            </p>
          </div>
        </span>
      </div>
    </div>
    <div class="input-otr-password">
      <p class="input-text-2">Confirm PIN (4 Digits)</p>
      <div
        *ngIf="f.cnfrm_pin_code.touched && f.cnfrm_pin_code.invalid"
        class="alert alert-danger"
      >
        <div class="error-text" *ngIf="f.cnfrm_pin_code.errors.required">
          Invalid Confirm Pin
        </div>
      </div>
      <input
        class="input-PIN"
        (focus)="onFocusConfirmPin()"
        (blur)="removeConfirmPintool()"
        type="text"
        name="cnfrm_pin_code"
        maxlength="4"
        id="cnfrm_pin_code"
        [(ngModel)]="inputModel.cnfrm_pin_code"
        formControlName="cnfrm_pin_code"
        (ngModelChange)="changeCnfrmPin($event)"
        onKeyPress="if(this.value.length==4) return false;"
      />
      <div *ngIf="ConfirmPinToolTip" class="tooltip">
        <span class="tooltiptext">
          <div style="margin: 3px">
            <p class="input-text-2" style="color: white">
              Please re-enter your PIN for confirmation
            </p>
          </div>
        </span>
      </div>
    </div> -->

    <h1 class="heading">Create Account</h1>
    <div class="input-otr-password">
      <div class="form-group">
        <label class="input-text-1">Username</label>
        <div
          *ngIf="f.username.touched || f.username.dirty"
          class="alert alert-danger"
        >
          <p *ngIf="f.username.errors?.minlength" class="error-text">
            <i class="fa fa-times mr-1" aria-hidden="true"></i>Username must be
            atleast 6 characters long.
          </p>
          <p *ngIf="f.username.errors?.maxlength" class="error-text">
            <i class="fa fa-times mr-1" aria-hidden="true"></i>Username can only
            be of 20 characters max.
          </p>
        </div>
        <input
          type="text"
          class="input"
          formControlName="username"
          [(ngModel)]="inputModel.username"
        />
      </div>
      <div class="form-group group-position">
        <label class="input-text-1">Password</label>

        <input
          class="input"
          [type]="!showPass ? 'password' : 'text'"
          formControlName="password"
          [(ngModel)]="inputModel.password"
        />
        <img
          [src]="
            !showPass ? 'assets/icons/hidden.png' : 'assets/icons/disclosed.png'
          "
          (click)="togglePassword()"
          class="img-inr eye-icon hand-cursor"
          height="25"
          alt="hidden"
        />
      </div>
      <div class="form-group group-position">
        <label class="input-text-1" style="margin-top: 2%"
          >Confirm Password</label
        >
        <input
          class="input"
          [type]="!showConfirmPass ? 'password' : 'text'"
          formControlName="confirm_password"
          [(ngModel)]="inputModel.confirm_password"
        />
        <img
          [src]="
            !showConfirmPass
              ? 'assets/icons/hidden.png'
              : 'assets/icons/disclosed.png'
          "
          (click)="toggleConfirmPassword()"
          class="img-inr eye-icon hand-cursor"
          height="25"
          alt="hidden"
        />
      </div>
      <div
        *ngIf="
          (f.password.touched || f.password.dirty) &&
          (f.confirm_password.touched || f.confirm_password.dirty)
        "
        class="alert alert-danger"
      >
        <p *ngIf="form.errors?.mismatch" class="error-text">
          <i class="fa fa-times mr-1" aria-hidden="true"></i>Passwords do not
          match.
        </p>
        <p
          *ngIf="!form.errors?.mismatch"
          class="error-text"
          style="color: green"
        >
          <i class="fa fa-check mr-1" aria-hidden="true"></i>Passwords matched
        </p>
      </div>
      <div *ngIf="f.password.touched || f.password.dirty">
        <p *ngIf="f.password.errors?.minlength" class="error-text">
          <i class="fa fa-times mr-1" aria-hidden="true"></i>Password must be
          atleast 8 characters long.
        </p>
      </div>
    </div>

    <div class="enable-otr">&nbsp;</div>
    <div class="action-otr login-btn" style="padding-top: 22px !important">
      <button
        id="signup-btn"
        [disabled]="form.invalid"
        class="btn-login hand-cursor"
        type="submit"
      >
        Register
      </button>
    </div>
  </form>
  <p class="power-footer">Powered By VYTLsft</p>
</div>
