import { NgxSpinnerService } from 'ngx-spinner';
import { MatDatepickerInput } from '@angular/material/datepicker';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { LoginService } from '../services/login.service';
import { verificationModel } from '../models/login';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css'],
})
export class VerificationComponent implements OnInit {
  LastNameToolTip: boolean;
  userType: string;
  CellPhoneToolTip: boolean;
  CreatPinToolTip: boolean;
  ConfirmPinToolTip: boolean;
  dobFlag: boolean;
  inputModel: verificationModel;
  minDate: Date;
  maxDate: Date;
  client_name: string;
  showPass: boolean;
  showConfirmPass: boolean;
  form: FormGroup = new FormGroup({});
  @ViewChild('dateOfBirth') dateOfBirth: ElementRef;
  @ViewChild(MatDatepickerInput) datepickerInput: MatDatepickerInput<any>;

  constructor(
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: ActivatedRoute,
    private route: Router
  ) {
    this.showPass = false;
    this.showConfirmPass = false;
    this.LastNameToolTip = false;
    this.CellPhoneToolTip = false;
    this.CreatPinToolTip = false;
    this.ConfirmPinToolTip = false;
    // this.isMatch=true
    this.dobFlag = false;
    this.client_name = '';
    this.minDate = new Date(1910, 4, 12);
    this.maxDate = new Date();
    this.router.params.subscribe((params) => {
      this.userType = params['user_type'];
    });
    this.inputModel = new verificationModel();
    if (
      !(localStorage.getItem('client_id') && localStorage.getItem('patient_id'))
    ) {
      this.route.navigate(['/login']);
    }
    if (localStorage.getItem('client_id')) {
      this.inputModel.client_id = localStorage.getItem('client_id');
    }
    if (localStorage.getItem('patient_id')) {
      this.inputModel.patient_id = localStorage.getItem('patient_id');
    }
    if (localStorage.getItem('client_name')) {
      this.client_name = localStorage.getItem('client_name');
    }

    this.form =
      this.userType === 'signup'
        ? fb.group({
            last_name: ['', [Validators.required]],
          })
        : fb.group({
            cell_phone: ['', [Validators.required]],
          });
  }

  onFocus() {
    this.LastNameToolTip = true;
  }
  onFocusCellPhone() {
    this.CellPhoneToolTip = true;
  }
  removeLastNametool() {
    this.LastNameToolTip = false;
  }
  removeCellPhoneToolTip() {
    this.CellPhoneToolTip = false;
  }

  onDateChange(event: any) {
    var x;
    x = this.dateOfBirth.nativeElement.value;
    x = moment(x).format('YYYY-MM-DD');
    this.dobFlag = true;
    if (x === 'Invalid date') {
      this.dobFlag = false;
    }
  }

  onClick(event) {
    this.dateOfBirth.nativeElement.focus();
    this.dateOfBirth.nativeElement.setSelectionRange(0, 0);
    // this.dobFlag = true;
  }

  onInputChange(value: string) {
    const inputValue = this.dateOfBirth.nativeElement.value;
    const formattedDate = moment(inputValue).format('YYYY-MM-DD');
    if (formattedDate === 'Invalid date') {
      this.dobFlag = false;
    } else {
      this.dobFlag = true;
    }
  }

  public mask = {
    guide: true,
    showMask: true,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  };

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.dobFlag = false;
  }

  signUp() {
    this.spinner.show();
    this.inputModel.date_of_birth = this.dateOfBirth.nativeElement.value;
    this.inputModel.date_of_birth = moment(
      this.inputModel.date_of_birth
    ).format('YYYY-MM-DD');
    if (!this.inputModel.last_name && this.userType === 'signup') {
      this.spinner.hide();
      return this.toastr.error('Please enter last name');
    } else if (this.inputModel.last_name && this.userType === 'signup') {
      this.inputModel.last_name = this.inputModel.last_name.trim();
    }
    if (!this.inputModel.cell_phone && this.userType === 'linkuser') {
      this.spinner.hide();
      return this.toastr.error('Please enter Cell Phone');
    } else if (this.inputModel.cell_phone && this.userType === 'linkuser') {
      this.inputModel.cell_phone = this.inputModel.cell_phone.trim();
    }
    if (this.inputModel.date_of_birth === 'Invalid date') {
      this.spinner.hide();
      return this.toastr.error('Please enter date of birth');
    }
    this.loginService.verification(this.inputModel).subscribe(
      (res) => {
        if (res.data.is_verified) {
          if (this.userType === 'linkuser') {
            this.loginService.setDataForlinkUser(
              this.inputModel.cell_phone,
              this.inputModel.date_of_birth
            );
            this.route.navigate(['/register', 'linkuser']);
          } else if (this.userType === 'signup') {
            this.loginService.setDataForSignup(
              this.inputModel.last_name,
              this.inputModel.date_of_birth
            );
            this.route.navigate(['/register', 'signup']);
          }
          this.spinner.hide();
        }
      },
      (error) => {
        this.spinner.hide();
        if (error && error.error.code === 400) {
          this.toastr.error(error.error.message);
        } else {
          this.toastr.error(error.error.message);
          console.log(error);
        }
      }
      // (response) => {
      //   if (response.data.token) {
      //     localStorage.setItem('token', response.data.token);
      //   }
      //   if (response.data.patient_name) {
      //     localStorage.setItem('patient_name', response.data.patient_name);
      //   }
      //   if (response.data.first_name) {
      //     localStorage.setItem('first_name', response.data.first_name);
      //   }
      //   if (response.data.last_name) {
      //     localStorage.setItem('last_name', response.data.last_name);
      //   }
      //   if (response.data.date_of_birth) {
      //     localStorage.setItem('date_of_birth', response.data.date_of_birth);
      //   }
      //   if (response.data.med_rec_nbr) {
      //     localStorage.setItem('med_rec_nbr', response.data.med_rec_nbr);
      //   }
      //   if (response.data.user_ids) {
      //     localStorage.setItem(
      //       'multi_user_ids',
      //       JSON.stringify(response.data.user_ids)
      //     );
      //   }
      //   localStorage.setItem(
      //     'credentials_registered',
      //     response.data.credentials_registered
      //   );
      //   this.spinner.hide();
      //   window.location.href = '/dashboard';
      // },
      // (error) => {
      //   this.spinner.hide();
      //   if (error && error.error.code === 303) {
      //     if (error.error.data && error.error.data.cell_phone_end) {
      //       localStorage.setItem(
      //         'cell_phone_end',
      //         error.error.data.cell_phone_end
      //       );
      //     }
      //     window.location.href = '/mfa';
      //   } else {
      //     this.toastr.error(error.error.message);
      //     console.log(error);
      //   }
      // }
    );
  }
}
