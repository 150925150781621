<div class="screen screen-4">
  <div class="logo">
    <img class="logo-img" src="assets/img/logo.png" alt="logo" />
  </div>
  <div class="container">
    <form [formGroup]="form" (ngSubmit)="verifyCode()">
      <h1>TWO-FACTOR AUTHENTICATION</h1>
      <!-- <input autofocus type="tel" name="pincode-1" maxlength="6" tabindex="1"
                     autocomplete="off" [textMask]="mask"> -->
      <div class="form__group form__pincode">
        <label
          >Enter 6-digit code that is being sent on your phone. Last 4 digit of
          phone number is
          <span style="color: #073786">{{ phone_digit }}</span></label
        >
        <input
          #pinOneElement
          autofocus
          type="tel"
          name="pincode-1"
          maxlength="1"
          [textMask]="{ mask: mask }"
          tabindex="1"
          placeholder="·"
          autocomplete="off"
          (keyup)="onDigitInput('pinOne', $event)"
          [(ngModel)]="pinOne"
          formControlName="pinOne"
        />
        <input
          type="tel"
          name="pincode-2"
          maxlength="1"
          [textMask]="{ mask: mask }"
          tabindex="2"
          placeholder="·"
          autocomplete="off"
          (keyup)="onDigitInput('pinTwo', $event)"
          [(ngModel)]="pinTwo"
          formControlName="pinTwo"
          class="noClick"
        />
        <input
          type="tel"
          name="pincode-3"
          maxlength="1"
          [textMask]="{ mask: mask }"
          tabindex="3"
          placeholder="·"
          autocomplete="off"
          (keyup)="onDigitInput('pinThree', $event)"
          [(ngModel)]="pinThree"
          formControlName="pinThree"
          class="noClick"
        />
        <input
          type="tel"
          name="pincode-4"
          maxlength="1"
          [textMask]="{ mask: mask }"
          tabindex="4"
          placeholder="·"
          autocomplete="off"
          (keyup)="onDigitInput('pinFour', $event)"
          [(ngModel)]="pinFour"
          formControlName="pinFour"
          class="noClick"
        />
        <input
          type="tel"
          name="pincode-5"
          maxlength="1"
          [textMask]="{ mask: mask }"
          tabindex="5"
          placeholder="·"
          autocomplete="off"
          (keyup)="onDigitInput('pinFive', $event)"
          [(ngModel)]="pinFive"
          formControlName="pinFive"
          class="noClick"
        />
        <input
          type="tel"
          name="pincode-6"
          maxlength="1"
          [textMask]="{ mask: mask }"
          tabindex="6"
          placeholder="·"
          autocomplete="off"
          (keyup)="onDigitInput('pinSix', $event)"
          [(ngModel)]="pinSix"
          formControlName="pinSix"
          class="noClick"
        />
      </div>
      <div class="resend-code">
        Didn't receive code?
        <a
          style="color: #073786; text-decoration: underline"
          class="hand-cursor"
          (click)="resendCode()"
          >&nbsp;Resend Code</a
        >
      </div>
      <div class="action-otr login-btn">
        <button
          type="submit"
          class="btn-continue hand-cursor"
          [disabled]="!form.valid"
        >
          CONTINUE
        </button>
      </div>
    </form>
  </div>
</div>
