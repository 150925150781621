import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AppointmentService } from '../services/appointment.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css'],
})
export class AppointmentsComponent implements OnInit, AfterViewInit {
  @ViewChild('owlCar', { static: false }) owlCar: any;
  height: any;
  width: any;
  response: any;
  recordIndex: any;
  dynamicSlides: any;
  arrayOfUsers: any;
  arrayOfAppoinments: any;
  arrayOfpatientAppointments: any;
  constructor(
    private appointmentSerice: AppointmentService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.dynamicSlides = {};
    this.arrayOfUsers = [];
    this.arrayOfAppoinments = [];
    this.arrayOfpatientAppointments = [];
    this.getusers();
    if (localStorage.getItem('token')) {
      if (
        this.appointmentSerice.appiontmentCurrentSlide === 0 ||
        this.appointmentSerice.appiontmentCurrentSlide === undefined
      ) {
        this.appointmentSerice.appiontmentCurrentSlide = 0;
        this.getAppointments(this.appointmentSerice.appiontmentCurrentSlide);
      }
    }
  }
  ngAfterViewInit() {
    if (this.owlCar) {
      this.owlCar.to(this.appointmentSerice.appiontmentCurrentSlide.toString());
    }
  }

  ngOnInit(): void {}
  getusers() {
    this.recordIndex = JSON.parse(localStorage.getItem('multi_user_ids'));
    if (this.recordIndex !== null && this.recordIndex.length > 0) {
      for (var i = 0; i < this.recordIndex.length; i++) {
        this.arrayOfUsers[i] = '';
        this.arrayOfAppoinments[i] = '';
        this.arrayOfpatientAppointments[i] = '';
      }
    }
  }
  confirmAppointment(AppointmentId: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      customClass: {
        popup: 'swal-resize'
      },
      confirmButtonText: 'Yes, Confirm it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.appointmentSerice
          .confirmAppointment(AppointmentId, true)
          .subscribe(
            (response) => {
              if (response.code === 200) {
                this.toastr.success('Confirmed');
                let currentUrl = this.router.url;
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigate([currentUrl]);
              } else {
                this.toastr.error('Error');
              }
            },
            (error) => {
              this.spinner.hide();
              this.toastr.error(error);
            }
          );
        // this.getAppointments(slideNo);
      }
    });
  }
  getAppointments(slideNo: number) {
    this.spinner.show();
    this.appointmentSerice.getAllAppointments(slideNo).subscribe(
      (response) => {
        this.response = response.data;
        this.dynamicSlides = {
          client: this.response.client,
          appointmentlist: this.response.list,
          patientAppointmentList: this.response.patient_appointments_list,
        };
        if (this.arrayOfUsers[slideNo] === '') {
          this.arrayOfUsers[slideNo] = this.dynamicSlides.client;
        }
        if (this.arrayOfAppoinments[slideNo] === '') {
          this.dynamicSlides.appointmentlist.forEach((items) => {
            items.scheduled_date = moment
              .parseZone(items.scheduled_date)
              .format('D MMM, YYYY');
            items.order_appointments.forEach((record) => {
              record.appointment_date = moment
                .parseZone(record.appointment_date)
                .format('D MMM, YYYY');
              record.appointment_time = moment
                .parseZone(record.appointment_time)
                .format('hh:mm a');
            });
          });
          this.arrayOfAppoinments[slideNo] = this.dynamicSlides.appointmentlist;
        }
        if (this.arrayOfpatientAppointments[slideNo] === '') {
          this.dynamicSlides.patientAppointmentList.forEach((items) => {
            items.appointment_date = moment
              .parseZone(items.appointment_date)
              .format('D MMM, YYYY');
            items.appointment_time = moment
              .parseZone(items.appointment_time)
              .format('hh:mm a');
          });
          this.arrayOfpatientAppointments[slideNo] =
            this.dynamicSlides.patientAppointmentList;
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error.error.message);
        console.log(error);
        this.spinner.hide();
      }
    );
  }
  getData($event) {
    this.spinner.show();
    this.appointmentSerice.appiontmentCurrentSlide = $event.startPosition;
    if (this.arrayOfUsers[$event.startPosition] === '') {
      this.getAppointments($event.startPosition);
    } else {
      this.spinner.hide();
      return;
    }
  }
  reset(): void {
    this.appointmentSerice.appiontmentCurrentSlide = 0;
  }

  customOptions: OwlOptions = {
    loop: false,
    dots: true,
    navSpeed: 600,
    nav: false,
    autoHeight: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      760: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
}
