import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TermOfUseService } from '../services/term-of-use.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../services/order.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ToastrService } from 'ngx-toastr';
import { error } from 'console';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @HostBinding('style.--primary-color') Primary_color = localStorage.getItem(
    'primary_color'
  )
    ? localStorage.getItem('primary_color')
    : '#073786';
  @HostBinding('style.--secondary-color') Secondry_color = localStorage.getItem(
    'secondry_color'
  )
    ? localStorage.getItem('secondry_color')
    : '#0D66FA';
  @HostBinding('style.--primary_color_light') Primary_color_light =
    localStorage.getItem('primary_color_light')
      ? localStorage.getItem('primary_color_light')
      : '#4A6186';
  private unsubscribeSource: Subject<any>;
  private _dbPromise;
  routeToLogin = false;
  showTermAndCondition: boolean;
  primary_color: string;
  primary_color_light: string;
  brand_logo: any;
  user_id: any;
  //
  constructor(
    private storage: StorageMap,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private termService: TermOfUseService,
    private orderService: OrderService
  ) {
    this.route.params.subscribe((params) => {
      this.user_id = params['id'];
    });
    let url = window.location.href;
    let splitEnd = url.split('?');
    if (this.user_id) {
      localStorage.setItem('user_id', this.user_id);
    }
    this.showTermAndCondition = false;
    if (
      (splitEnd[1] && localStorage.getItem('patient_id')) ||
      localStorage.getItem('user_id') ||
      this.user_id
    ) {
      if (
        (localStorage.getItem('client_id') &&
          localStorage.getItem('patient_id')) ||
        localStorage.getItem('user_id') ||
        this.user_id
      ) {
        this.validateTermAndConditions();
      }
    } else {
      this.routeToLogin = true;
    }
  }

  ngOnInit(): void {
    if (this.routeToLogin) this.router.navigate(['/login']);
    this.getBranding();
    this.unsubscribeSource = new Subject<any>();
    // this.initSubscription();
  }

  async getBranding() {
    if (
      (localStorage.getItem('patient_id') &&
        localStorage.getItem('client_id')) ||
      localStorage.getItem('user_id') ||
      this.user_id
    ) {
      if (!localStorage.getItem('primary_color')) {
        this.spinner.show();
        let body;
        if (localStorage.getItem('user_id') || this.user_id) {
          body = {
            user_id: localStorage.getItem('user_id') || this.user_id,
          };
        } else if (
          localStorage.getItem('patient_id') &&
          localStorage.getItem('client_id')
        ) {
          body = {
            patient_id: localStorage.getItem('patient_id'),
            client_id: localStorage.getItem('client_id'),
          };
        }
        await this.orderService.getBranding(body).subscribe(
          (res) => {
            localStorage.setItem(
              'primary_color',
              res.data?.primary_color || '#073786'
            );

            this.primary_color = res.data.primary_color;
            this.primary_color_light = this.getLightColor(this.primary_color);

            localStorage.setItem(
              'primary_color_light',
              this.primary_color_light || '#4A6186'
            );

            const url = res.data.logo_url?.split('?');
            this.brand_logo = url ? url[0] : 'assets/img/logo.png';
            localStorage.setItem(
              'secondry_color',
              res.data?.secondary_color || '#005EFA'
            );
            localStorage.setItem('brand_logo', this.brand_logo.split('?')[0]);

            this.Primary_color = this.primary_color || '#073786';
            this.Secondry_color = res.data.secondary_color || '#005EFA';
            this.Primary_color_light = this.primary_color_light || '#4A6186';

            this.spinner.hide();
          },
          (error) => {
            localStorage.setItem('primary_color', '#073786');
            localStorage.setItem('secondary_color', '#005EFA');
            this.primary_color_light = this.getLightColor('#073786');
            localStorage.setItem('brand_logo', 'assets/img/logo.png');
            console.error(error);
            this.spinner.hide();
          }
        );
      } else {
        this.primary_color = localStorage.getItem('primary_color');
        this.brand_logo = localStorage.getItem('brand_logo')
          ? localStorage.getItem('brand_logo')
          : 'assets/img/logo.png';
      }
    }
  }

  getLightColor(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
          result[3],
          16
        )},0.6)`
      : null;
  }

  initSubscription() {
    this.subscribeToQueryParams();
  }

  ngOnDestroy() {
    this.unsubscribeSource.next();
    this.unsubscribeSource.complete();
  }

  private subscribeToQueryParams() {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribeSource))
      .subscribe((params) => {
        if (
          (localStorage.getItem('client_id') &&
            localStorage.getItem('patient_id')) ||
          localStorage.getItem('user_id')
        ) {
          this.validateTermAndConditions();
        }
      });
  }
  delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
  validateTermAndConditions() {
    let practice_id;
    if (localStorage.getItem('practice_id')) {
      practice_id = localStorage.getItem('practice_id');
    }
    let patientId = '0',
      clientId = '0',
      user_id;
    if (localStorage.getItem('client_id'))
      clientId = localStorage.getItem('client_id');
    if (localStorage.getItem('patient_id'))
      patientId = localStorage.getItem('patient_id');
    if (localStorage.getItem('user_id'))
      user_id = localStorage.getItem('user_id');
    if ((clientId && patientId) || user_id) {
      this.termService
        .validateTermAndConditions(clientId, patientId, practice_id, user_id)
        .subscribe(
          (rsp) => {
            if (rsp.data.unique_patient) {
              localStorage.setItem(
                'patient_id',
                rsp.data.unique_patient.uniq_id
              );
            }
            if (rsp.data.client) {
              localStorage.setItem('client_id', rsp.data.client.id);
            }
            //--existing flow
            if (rsp.data && rsp.data.has_account) {
              this.router.navigate(['/login']);
            } else if (
              rsp.data &&
              !rsp.data.has_account &&
              rsp.data.has_account_with_other_client
            ) {
              localStorage.setItem('client_name', rsp.data.client.description);
              this.router.navigate(['/verification', 'linkuser']);
            } else if (
              rsp.data &&
              !rsp.data.has_account &&
              !rsp.data.has_account_with_other_client
            ) {
              localStorage.setItem('client_name', rsp.data.client.description);
              this.router.navigate(['/verification', 'signup']);
            }
          },
          (error) => {
            if (error === 404) {
              this.showTermAndCondition = true;
            }
            if (error && error.error && error.error.code === 404) {
              this.showTermAndCondition = true;
            }
            if (error && error.error && error.error.code === 400) {
              this.toastr.error(error.error.message);
            }
            this.spinner.hide();
            console.log(error);
          }
        );
    }
    // });
  }

  navigateTermOfUse() {
    window.location.href = '/term-of-use';
  }
}
