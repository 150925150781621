<div class="screen screen-8">
  <div style="margin: 0 0 24px 0 !important;">
    <a style="cursor: pointer" (click)="checkUser()">
      <i class="fa fa-arrow-left left-link" aria-hidden="true"></i>
    </a>
    <p class="heading">Easy Access</p>
  </div>
  <div *ngIf="recordIndex">
    <owl-carousel-o [options]="customOptions" (translated)="onDragged($event)">
      <ng-container>
        <ng-template
          carouselSlide
          *ngFor="let pay of recordIndex; let i = index"
        >
          <div class="screen-6">
            <div class="client-otr" style="text-align: center">
              <div class="icon-otr">
                <span class="circle"></span>
                <svg
                  class="location-icon"
                  width="20"
                  height="24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.343 4.343A8 8 0 0118 10c0 3.098-2.016 6.104-4.226 8.437A29.412 29.412 0 0110 21.773a29.418 29.418 0 01-3.774-3.335C4.016 16.103 2 13.097 2 10a8 8 0 012.343-5.657zm5.102 19.489L10 23l-.555.832a1 1 0 001.11 0L10 23l.555.832h.001l.002-.002.007-.005.023-.015.082-.057c.072-.05.174-.12.302-.214a31.433 31.433 0 004.254-3.727C17.516 17.397 20 13.903 20 10a10 10 0 00-20 0c0 3.902 2.484 7.396 4.774 9.813a31.428 31.428 0 004.254 3.726 19.098 19.098 0 00.384.27l.023.016.007.005.002.001zM8 10a2 2 0 114 0 2 2 0 01-4 0zm2-4a4 4 0 100 8 4 4 0 000-8z"
                    fill="#fff"
                  />
                </svg>
              </div>
              <ul class="text-otr" style="margin: 10px">
                <li
                  class="text"
                  *ngIf="arrayOfUsers[i] !== '' && arrayOfUsers[i]?.paymentInfo"
                >
                  {{ arrayOfUsers[i]?.paymentInfo.name }}
                </li>
                <li
                  class="text"
                  *ngIf="arrayOfUsers[i] !== '' && arrayOfUsers[i]?.paymentInfo"
                >
                  {{ arrayOfUsers[i]?.paymentInfo?.address }}
                </li>
                <li
                  class="text"
                  *ngIf="arrayOfUsers[i] !== '' && arrayOfUsers[i]?.paymentInfo"
                >
                  <address class="text address">
                    {{ arrayOfUsers[i]?.paymentInfo?.city }},
                    {{ arrayOfUsers[i]?.paymentInfo?.state }}
                    {{ arrayOfUsers[i]?.paymentInfo?.zip }}
                  </address>
                </li>
                <li
                  class="text last-text"
                  *ngIf="arrayOfUsers[i] !== '' && arrayOfUsers[i]?.paymentInfo"
                >
                  <a href="tel: {{ arrayOfUsers[i]?.paymentInfo?.phone }}">
                    {{ arrayOfUsers[i]?.paymentInfo?.phone }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="pay-main" style="height: 80%">
            <div class="icon-main">
              <div *ngFor="let order of arrayOfUsers[i]?.orders">
                <div class="icon-otr" *ngIf="pay">
                  <div class="text-otr">
                    <p class="desc" *ngIf="order?.provider">
                      {{ order?.provider?.provider_name }}
                    </p>
                    <p
                      class="heading"
                      style="padding: 10px 0 0 0"
                      *ngIf="order?.est_out_of_pocket"
                    >
                      {{
                        order?.est_out_of_pocket
                          | currency : "USD" : "symbol" : "1.2-2"
                      }}
                    </p>
                  </div>
                </div>
                <div
                  class="pay-btn"
                  *ngIf="
                    order?.est_out_of_pocket > 0 &&
                    (order?.is_paid === false || order?.is_paid === null)
                  "
                >
                  <button
                    class="btn-pay hand-cursor"
                    type="button"
                    (click)="checkout(order?.est_out_of_pocket, order)"
                  >
                    <i class="fa fa-apple"></i> PAY
                  </button>
                </div>
                <div class="pay-btn" *ngIf="order?.is_paid === true">
                  <button
                    class="btn-pay-disabled hand-cursor"
                    type="button"
                    [disabled]="true"
                  >
                    <i class="fa fa-apple"></i> PAID
                  </button>
                </div>
              </div>
              <ng-container
                *ngIf="
                  arrayOfStatments[i] !== '' && arrayOfStatments[0]?.length > 0
                "
              >
                <div
                  style="overflow-x: auto"
                  *ngIf="arrayOfUsers[i] !== '' && arrayOfUsers[i]?.paymentInfo"
                >
                  <hr
                    style="
                      background-color: #ccc;
                      margin-left: 0.3rem;
                      margin-right: 0.3rem;
                      border: 1px solid #ddd;
                    "
                  />
                  <h4
                    style="
                      text-align: center;
                      font-family: Gilroy-Light;
                      padding-bottom: 1rem;
                      padding-top: 1rem;
                      color: #252f5a;
                    "
                  >
                    Statements
                  </h4>
                  <table class="bordered">
                    <tr>
                      <th style="text-align: center">
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </th>
                      <th
                        style="
                          font-family: 'Gilroy-Light';
                          font-size: 14px;
                          color: #252f5a !important;
                        "
                      >
                        Statement Date
                      </th>
                      <th
                        style="
                          font-family: 'Gilroy-Light';
                          font-size: 14px;
                          color: #252f5a !important;
                        "
                      >
                        Balance
                      </th>
                      <th style="text-align: center">
                        <i class="fa fa-usd" aria-hidden="true"></i>
                      </th>
                    </tr>
                    <tr
                      *ngFor="let stat of arrayOfStatments[i]?.paymentStatment"
                    >
                    <td>
                      <button
                        type="button"
                        class="btn-view hand-cursor"
                        (click)="
                          checkUser()
                        "
                      >
                        View
                      </button>
                    </td>
                      <td
                        style="
                          font-family: 'Gilroy-Light';
                          font-size: 14px;
                          font-weight: bolder;
                          color: #7a81a0 !important;
                        "
                      >
                        {{ stat.print_date }}
                      </td>
                      <td
                        style="
                          font-family: 'Gilroy-Light';
                          font-size: 14px;
                          font-weight: bolder;
                          color: #7a81a0 !important;
                        "
                      >
                        {{ stat.amount }}
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn-view hand-cursor"
                          [disabled]="
                            isPaymentBtnDisabled(stat.print_date) ||
                            stat.is_paid
                          "
                          (click)="
                            stat.is_paid = 'loading';
                            createStatementPaymentSession(stat.id)
                          "
                        >
                          <span *ngIf="stat.is_paid === 'loading'"
                            >Loading</span
                          >
                          <span *ngIf="stat.is_paid !== 'loading'">{{
                            stat.is_paid ? "Paid" : "Pay"
                          }}</span>
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </ng-container>
              <div
                class="main"
                [ngStyle]="{
                  'min-height':
                    arrayOfStatments[i]?.paymentStatment &&
                    arrayOfStatments[i]?.paymentStatment.length > 0
                      ? '36%'
                      : '55%'
                }"
                *ngIf="
                  dynamicSlides.paymentInfo && dynamicSlides.paymentInfo[0]
                "
              >
                <div id="bottom">{{ currPageNo }}/{{ totalSlides }}</div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
    <div
      class="order-otr"
      style="margin: 0 0 24px 0 !important; cursor: pointer"
    >
      <p class="heading">Contact Us</p>
    </div>
    <div
      style="
        overflow-x: hidden;
        border-style: solid;
        background-color: #fff;
        border-radius: 5px;
        border-width: 1px;
        border-color: #d0d8dc;
        padding: 10px;
        box-shadow: none;
        margin-top: 25px;
      "
    >
      <ul class="provider-list">
        <li *ngIf="provider" class="provider-item">
          <a
            class="hand-cursor"
            (click)="
              navigateChatBox(provider.practice_id, provider.practice_name)
            "
          >
            <div class="order">
              <span class="icon"></span>
              <p class="desc">Chat with {{ provider.practice_name }}</p>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <p class="power-footer">Powered By VYTLsft</p>
  </div>
</div>
